import { parseBoolean, parseString, receiveFecha, receiveFechaFormat2, sendMultipart } from "../../../adapters/helpers"

const TicketPublicAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idticket || '',
                    codigoticket: item.codigoticket || '',
                    estado: item.estado || '',
                    fechaFinIntervencion: item.fechaFinIntervencion || '',
                    fechaInicioIntervencion: item.fechaInicioIntervencion || '',
                    fechaRegistro: item.fechaRegistro || '',
                    fechaUltimaModificacion: item.fechaUltimaModificacion || '',
                    identidad: item.identidad || '',
                    idpersonaregistro: item.idpersonaregistro || '',
                    idtecnicoasociado: item.idtecnicoasociado || '',
                    idticket: item.idticket || '',
                    idtkproducto: item.idtkproducto || '',
                    producto: item.producto || '',
                    ticket: item.ticket || '',
                    ticket_mensajes: item.ticket_mensajes || [],
                    ticket_documents: item.ticket_documents || [],
                    tipoIncidencia: item.tipoIncidencia || '',
                    totalminutosgestion: item.totalminutosgestion || '',
                    urgencia: item.urgencia || '',
                    nombre_entidad: item.nombre_entidad || '',
                    nombre_producto: item.nombre_producto || '',
                    nombre_urgencia: item.nombre_urgencia || '',
                    nombre_tecnicoasociado: item.nombre_tecnicoasociado || '',
                    nombre_estado: item.nombre_estado || '',
                    personaregistro: item.nombre_personaregistro || '',
                    tecnicoasociado: item.tecnicoasociado || '',
                    esPropietario: item.esPropietario || false,
                    ticket_personas_c_c: item.ticket_personas_c_c || [],
                    email_destinatario_link: item.email_destinatario_link || '',
                    nombre_destinatario_link: item.nombre_destinatario_link || '',
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                // const items = dataTemp?.data?.map((item: any) => (this.schema(item)))
                const items = this.schema(dataTemp)

                return {
                    data: {
                        ...items
                    },
                    status: data?.status
                }
            }
        },
    },
    
    GET_BUSCAR_PERSONAS: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    datospersonas: item.datospersonas?.map((item2: any) => {
                        return {...item2, id: item2.idpersona}
                    }) || [],
                    resumen: item.resumen || {},
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                // const items = dataTemp?.data?.map((item: any) => (this.schema(item)))
                const items = this.schema(dataTemp)

                return {
                    data: {
                        ...items
                    },
                    status: data?.status
                }
            }
        },
    },

    POST: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    idtecnicoasociado: item.idtecnicoasociado || '',
                    idpersonaregistro: item.idpersonaregistro || '',
                    identidad: item.identidad || '',
                    ticket: item.ticket || '',
                    tipoIncidencia: item.tipoIncidencia || '',
                    producto: item.producto || '',
                    urgencia: item.urgencia || '',
                    totalminutosgestion: item.totalminutosgestion || '',
                    asunto: item.asunto || '',
                    descripcion: item.descripcion || '',
                    estado: item.estado || '',
                    'documents[]': item['documents[]'] || [],
                    'listadestinatarios[]': item['listadestinatarios[]'] || [],
                }

                return data
            },

            adapter: function (data: any) {

                const items = data

                return items
            }
        },
    },

    PATCH: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    nombreoferta: item.nombreoferta || '',
                    idpais: item.idpais || '',
                    idpersona: item.idpersona || '',
                    identidad: item.identidad || '',
                    pub_nom_emp: parseBoolean(item.pub_nom_emp),
                    observaciones: item.observaciones || '',
                    idtipocontrato: item.idtipocontrato || '',
                    idestadooferta: item.idestadooferta || '',
                    categoriaoferta: item.categoriaoferta || '',
                    numerovacantes: item.numerovacantes || '',
                    idsalario: item.idsalario || '',
                    fpublicacioninicio: item.fpublicacioninicio || '',
                    fpublicacionfin: item.fpublicacionfin || '',
                    idhorario: item.idhorario || '',
                    oferta: item.oferta || '',
                    funcionesoferta: item.funcionesoferta || '',
                    ticket: item.ticket || '',
                    
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    PATCH_CHANGE_ESTADO: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    estado: item.estado || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    DELETE: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },
    },

    PATCH_IMAGEN: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    imagen: item.imagen || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return sendMultipart(items)
            }
        },
    },

    DELETE_IMAGEN: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },
    },
    
    PATCH_NIVEL_IDIOMA: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                // const data = {
                //     idiomas: item.idiomas || [],
                //     logica_buscar_idioma: item.logica_buscar_idioma || false
                // }

                return item
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    PATCH_TIPO_ESTUDIO: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                // const data = {
                //     tiposestudios: item.tiposestudios || [],
                // }

                return item
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    PATCH_FUNCION: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                // const data = {
                //     funcion: item.funcion || [],
                // }

                return item
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    POST_SUBIR_ADJUNTO: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    tituloadjunto: item.tituloadjunto || '',
                    adjunto: item.adjunto || '',
                }

                if (!data.tituloadjunto) delete data.tituloadjunto

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return sendMultipart(items)
            }
        },
    },

    DELETE_ADJUNTO: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                return {
                    data: items,
                    status: data?.status
                }
            }
        },
    },

}

export {
    TicketPublicAdapters,
}