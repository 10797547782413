// import queryString from 'query-string';

import { cloneDeep } from "lodash"

// export function getQueryStringSearchCollegiats(objParams) {
//   return `?${queryString.stringify({
//     colegio_id: objParams.colegio_id ? objParams.colegio_id : undefined,
//     page: objParams.page !== '' ? objParams.page : undefined
//   })}`
// }


export function getMsgInputServerColor(objParams, name) {
  return objParams?.error?.response?.data?.data?.[name]
}

export function getMsgInputColor(objParams, name) {
  return objParams[name] && !objParams[name]?.isOk
}

export function getValuesParams(objValuesSearch) {
  let urlParams = {}

  for (const key of Object.keys(objValuesSearch)) {
    if (objValuesSearch[key] !== '') {
      urlParams[key] = objValuesSearch[key]
    }
  }

  return urlParams
}

export function getURLQuery(objUrlParams) {
  return Object.keys(objUrlParams).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(objUrlParams[k])).join('&');
}

export function isOrderPrev_(effectTempIndex, effectTempIndex1) {
  return (effectTempIndex.indexOf('orderPrev-') !== -1) || (effectTempIndex1.indexOf('orderPrev-') !== -1)
}

export function breakExistA1A3(exista1a3, index) {
  if (exista1a3 && (index === 'a1' || index === 'a2' || index === 'a3')) {
    return true
  }

  if (!exista1a3 && (index === 'a1a3')) {
    return true
  }
  return false
}

export function breakExistRecuperacion(exista1a3, index) {
  if (exista1a3 && (index === 'kg_para_reutilizacion' || index === 'kg_para_reciclado' || index === 'kg_para_valorizacion_energetica')) {
    return true
  }

  if (!exista1a3 && (index === 'kg_mix_reutilizacion_reciclado_valorizacion_energetica')) {
    return true
  }
  return false
}

// export function isAdmin(perfil) {
//   return perfil === 'admin_ares'
// }

// export function isCaateeb(perfil) {
//   return perfil === 'admin_caateeb'
// }

// export function isConsultor(perfil) {
//   return perfil === 'consultor'
// }

// export function isVerificador(perfil) {
//   return perfil === 'verificador'
// }

// export function isOrganizacion(perfil) {
//   return perfil === 'organizacion'
// }

// export function isModuleOrganizacion(modulo_app) {
//   return modulo_app === 'organizacion'
// }

// export function isModuleAdministrador(modulo_app) {
//   return modulo_app === 'administrador'
// }

// export function isModuleConsultor(modulo_app) {
//   return modulo_app === 'consultor'
// }


export function dataURLtoFile(dataurl, filename) {

  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

// Helpers para firmado


export function getNavigator() {
  var ua = navigator.userAgent;
  var tem;
  var M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE ' + (tem[1] || '');
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edg)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(' ');
}

export function getUrlGuia(navegador) {

  if (navegador === 'Chrome') {
    return 'guia_chrome'
  }

  if (navegador === 'Firefox') {
    return 'guia_firefox'
  }

  if (navegador === 'Edg') {
    return 'guia_edge'
  }

  return 'guia_chrome'
}

export function getObjectKeysUsed(obj) {

  const objTemp = {}

  for (const key in obj) {
    if (obj[key]) {
      objTemp[key] = obj[key]
    }
  }

  return objTemp

}

export function getObjectKeysMultilineUsed(obj) {

  const objTemp = {}

  for (const key in obj) {
    if (key.indexOf('.') !== -1) {
      objTemp[key] = obj[key]
    }
  }

  return objTemp

}

export function getLabelByModules(array) {

  const size = array.length;

  if (size === 1) {

    return `(${array[0].idUpper})`

  } else if (size === 2) {

    return `(${array[0].idUpper} y ${array[1].idUpper})`

  } else if (size === 3) {

    return `(${array[0].idUpper} , ${array[1].idUpper} y ${array[2].idUpper})`
  }

  return ''
}

export function getLabelByModulesv2(array, valiKeys) {

  const arrayTemp = []

  for (let i = 0; i < valiKeys.length; i++) {

    const result = array.find(item => item.modulo_param_id === valiKeys[i])

    if (result) {
      arrayTemp.push(result)
    }
  }

  const size = arrayTemp.length;

  if (size === 1) {

    return `${arrayTemp[0].label}`

  } else if (size === 2) {

    return `${arrayTemp[0].label} y ${arrayTemp[1].label}`

  } else if (size === 3) {

    return `${arrayTemp[0].label} , ${arrayTemp[1].label} y ${arrayTemp[2].label}`

  } else if (size === 4) {

    return `${arrayTemp[0].label} , ${arrayTemp[1].label} , ${arrayTemp[2].label} y ${arrayTemp[3].label}`
  }

  return ''
}

export function getModelos(dapcons_modelos = []) {

  const modelosTemp = dapcons_modelos.map(item => {
    return {
      modelo_id: item.modelo_id,
      modelo_nombre: item.modelo_nombre,
    }
  })

  return modelosTemp
}

export function getFormatDiaMesAnio(fecha) {

  return fecha?.split('-')?.reverse()?.join('/')
}

export function guardarDatos(form, modulo_app) {

  const userRecovery = JSON.parse(localStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE + '_REMEMBER_PASSWORD')) || {}

  userRecovery[modulo_app] = {
    usuario: form.usuario,
    password: form.password,
    modulo_app: form.usuario ? modulo_app : ''
  }

  localStorage.setItem(process.env.REACT_APP_MAIN_VARIABLE + '_REMEMBER_PASSWORD', JSON.stringify(userRecovery));
}

// Helpers para enginyers

export function isNumeric(str) {
  if (typeof str != "string")
    return false
  return !isNaN(str) && !isNaN(parseFloat(str))
}

export function isAdministrador(perfiles) {

  const res = perfiles.filter(
    item => item.alias !== 'colegiado' &&
      item.alias !== 'precolegiado' &&
      item.alias !== 'externo')

  return res.length > 0
}

export function isAdminAres(perfiles) {

  return perfiles.find(item => item.alias === 'admin_ares')
}

export function filterPerfilesAdministradores(array) {

  return array?.filter(item2 => item2.alias !== 'colegiado' && item2.alias !== 'precolegiado' && item2.alias !== 'externo') || []
}

export function filterSinPerfilPorDefecto(array) {

  return array?.filter(item2 => item2.alias !== 'perfil_por_defecto') || []
}

export function filterModulosAdministrador(array) {

  return array?.filter(item2 =>
    item2.alias === 'administracion' ||
    item2.alias === 'admin_visados' ||
    item2.alias === 'admin_gestor_contenidos' ||
    item2.alias === 'admin_facturacion' ||
    item2.alias === 'admin_inscripciones' ||
    item2.alias === 'admin_alquiler_aparatos' ||
    item2.alias === 'admin_guia_profesionales' || 
    item2.alias === 'admin_bolsa_trabajo'
  ) || []
}


export function getSelectByKeys(staticService) {

  const res = Object.keys(staticService).map((key) => {
    return {
      id: key,
      nombre: staticService[key]
    }
  })

  return res
}

export function getBaseImp(cantidad, precio, porcentajedescuento) {

  return Number(cantidad) * Number(precio) - (Number(porcentajedescuento) * (Number(cantidad) * Number(precio)) / 100)
}

export function getDateInput() {
  // Fecha de registro (hoy)
  const date2 = new Date()
  return `${date2.getFullYear()}-${("0" + (date2.getMonth() + 1)).slice(-2)}-${("0" + date2.getDate()).slice(-2)}`

}


export function getDataFile(fetchData, handleReset, nombre = 'default', extension = 'pdf') {

  if (fetchData.data) {
    // 2. Create blob link to download
    const docData = fetchData.data

    const filename = docData?.headers?.['content-disposition']?.split('filename=')?.[1] || `${nombre}.${extension}`
    const blobData = docData?.data

    const instanceBlob = new Blob([blobData])

    const date = new Date()

    const url = window.URL.createObjectURL(instanceBlob);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}_${filename}`);
    // 3. Append to html page
    document.body.appendChild(link);

    // 4. Force download
    link.click();

    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);

    handleReset()

  }
}

export function getBase64(file) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    // console.log(reader.result);
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  };
}

export function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  })
};

export function isStatusSucess(status) {

  return String(status)?.[0] === '2'
}


export function isStatusError(status) {

  return String(status)?.[0] === '4' || String(status)?.[0] === '5'
}

export function fixHourWithZero(data) {

  return String(data)?.length === 1 ? '0' + String(data) : String(data)
}

export function getParseDate(data) {

  return `${data.getFullYear()}-${fixHourWithZero(data.getMonth() + 1)}-${fixHourWithZero(data.getDate())}`
}

export function getParseHours(data) {

  return fixHourWithZero(data.getHours()) + ':' + fixHourWithZero(data.getMinutes())
}

export function setUrlByParams(urlParams, ...exceptKeys) {

  const urlParamsTemp = cloneDeep(urlParams)

  for (let i = 0; i < exceptKeys.length; i++) {
    
    delete urlParamsTemp[exceptKeys[i]]
  }

  const query = getURLQuery(urlParamsTemp)

  window.history.replaceState(null, '', `?${query}`);
}

export function perteneceIDRemesa(fetchData, idCondicionPago) {
  
  const listIdsCondiciones = fetchData?.data?.items?.map(item => item.id)

  return listIdsCondiciones?.includes(idCondicionPago) || false
}

export function perteneceIDTransferencia(fetchData, idCondicionPago) {
  
  const listIdsCondiciones = fetchData?.data?.items?.map(item => item.id)

  return listIdsCondiciones?.includes(idCondicionPago) || false
}

export function getNumberOfDays(start, end) {
  // const date1 = new Date(start);
  // const date2 = new Date(end);

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = end.getTime() - start.getTime();

  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay);

  return diffInDays;
}

export function eliminarCookies() {
  document.cookie?.split(";")?.forEach(function(c) {
    document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
}

export function existNumber(number) {
  
  if (number === undefined || number === null || number === '') return false

  return true
}

export function getReturnOrder(number1, number2, number3) {
  
  if (existNumber(number1)) return number1

  if (existNumber(number2)) return number2

  return number3
}


export function parseCharactersToHtml(string) {

  if (!string) string = ''

  return String(string).replace(/&amp;/g, "&")
  
}

export function hasTextContent(htmlString) {

  const tempElement = document.createElement('div');
  
  tempElement.innerHTML = htmlString;
  
  const textContent = tempElement.textContent || tempElement.innerText || '';

  return textContent.trim().length > 0 ? 'content' : '';
}

export function formatFecha(fechaT) {

  const fecha = new Date(fechaT)
  const hoy = new Date();
  const ayer = new Date(hoy);
  ayer.setDate(hoy.getDate() - 1);
  const anteayer = new Date(hoy);
  anteayer.setDate(hoy.getDate() - 2);

  // Elimina la hora para comparar solo la fecha
  const fechaSinHora = new Date(fecha.getFullYear(), fecha.getMonth(), fecha.getDate());

  if (fechaSinHora.getTime() === hoy.setHours(0, 0, 0, 0)) {
      return "Hoy";

  } else if (fechaSinHora.getTime() === ayer.setHours(0, 0, 0, 0)) {

      return "Ayer";

  } else if (fechaSinHora.getTime() === anteayer.setHours(0, 0, 0, 0)) {
    
    return anteayer.toLocaleDateString('es-ES', { weekday: 'long' });
  
  } else {
    
      // Devuelve la fecha en un formato específico si es anterior a anteayer
      return fecha.toLocaleDateString();  // Puedes ajustar el formato según tus necesidades
  }
}

export function convertirMinutos(minutos = 0) {
  const horas = Math.floor(minutos / 60);
  const minutosRestantes = minutos % 60;
  return {
      horas: horas,
      minutos: minutosRestantes
  };
}