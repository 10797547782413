import React, { useEffect, useRef, useState } from "react";
import { useLang } from "../../../language";
import { isEmpty } from "lodash";
import { ListFiles } from "../../ListFiles/ListFiles";
import './TicketMensaje.css'
import { Alert, Button } from "react-bootstrap";
import { convertirMinutos, formatFecha } from "../../../helpers/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPencil } from "@fortawesome/free-solid-svg-icons";

export const TicketMensaje = ({ asunto, descripcion, fecha, className = '', observaciones, remitente,
    ticket_mensaje_documentos, onClickDelete, hora, soloadmins, handleShow, totalminutosgestion }: any) => {

    const Lang = useLang()

    const maxHeight = 120

    const [isExpanded, setIsExpanded] = useState(false);

    const [isOverflowing, setIsOverflowing] = useState(false);

    const contentRef: any = useRef(null);

    useEffect(() => {

        if (contentRef.current.scrollHeight > maxHeight) {
            setIsOverflowing(true);
        }
    }, [maxHeight]);


    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    const [showButton, setShowButton] = useState(false);
    const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });
    const [imageUrl, setImageUrl] = useState(""); // Estado para almacenar la URL de la imagen

    const handleMouseEnter = (e: any) => {
        // Obtener las coordenadas de la imagen

        if (e.target.tagName === "IMG") {

            const rect = e.target.getBoundingClientRect();

            // Obtener las coordenadas del contenedor
            const containerRect = contentRef.current.getBoundingClientRect();

            // Ajustar la posición del botón centrado sobre la imagen
            setButtonPosition({
                top: rect.top - containerRect.top + 20,
                left: rect.left - containerRect.left + (rect.width - 50)
            });

            setImageUrl(e.target.src)
            setShowButton(true);
        }
    };

    const handleMouseLeave = () => {
        setShowButton(false);  // Ocultar el botón al salir del hover
    };

    const handleButtonClick = () => {
        if (imageUrl) {
            const link = document.createElement('a'); // Crear un enlace
            link.href = imageUrl; // Asignar la URL de la imagen
            link.download = 'image.png'; // Asignar un nombre para la descarga
            document.body.appendChild(link); // Añadir el enlace al cuerpo
            link.click(); // Simular el clic en el enlace para iniciar la descarga
            document.body.removeChild(link); // Eliminar el enlace del DOM
        }
      };


    return (
        <article className={`TicketMensaje__Item card p-3 mb-4 ${className}`}>

            <div className="d-flex justify-content-between mb-1 flex-wrap">
                <h6 className='h6 fs-115 mb-0'>
                    {
                        soloadmins &&
                        <Alert variant='danger' className='mb-0 me-1 p-1 text-center d-inline-block fs-6'>
                            <small>{Lang('OCULTO')}</small>
                        </Alert>
                    } {asunto}
                </h6>
                <p className='mb-0 ms-auto'>
                    <span className="text-capitalize">{formatFecha(fecha)},</span> <span>{hora}</span> <h6 className="d-inline">{remitente}</h6>
                    {
                        handleShow &&
                        <>
                            <Button size="sm" variant="outline-primary" onClick={handleShow} className="ms-1">
                                <FontAwesomeIcon icon={faPencil} />
                            </Button>
                            <div className="text-end mt-1">
                                <small>{convertirMinutos(totalminutosgestion).horas}Hrs {convertirMinutos(totalminutosgestion).minutos}Mins </small>
                            </div>
                        </>
                    }
                </p>
            </div>

            {
                !isEmpty(ticket_mensaje_documentos) &&

                <div className="col-12 mb-3">
                    <ListFiles
                        isBlob={false}
                        inline={true}
                        list={ticket_mensaje_documentos?.map((item: any) => {
                            return {
                                titulo: item.originalfilename,
                                url: item.document_url,
                                id: item.idticketdocument
                            }
                        }) || []}
                        size='small'
                        onClickDelete={onClickDelete}
                    />
                </div>
            }

            <div
                ref={contentRef}
                className={`TicketMensaje__descripcion text ${isExpanded ? "expanded" : "collapsed"}`}
                style={{ maxHeight: isExpanded ? "none" : `${maxHeight}px` }}
            >

                <p>
                    <div
                        ref={contentRef}
                        className={`TicketMensaje__descripcion text ${isExpanded ? "expanded" : "collapsed"}`}
                        style={{ maxHeight: isExpanded ? "none" : `${maxHeight}px` }}
                        onMouseMove={handleMouseEnter}  // Escucha el movimiento del mouse dentro del contenedor
                        onMouseLeave={handleMouseLeave} // Asegura que el hover se quite al salir del contenedor
                    >
                        <div dangerouslySetInnerHTML={{ __html: descripcion }} />

                        {showButton && (
                            <button
                                className="btnHref btn btn-outline-primary btn-sm"
                                style={{
                                    position: 'absolute',
                                    top: `${buttonPosition.top}px`,
                                    left: `${buttonPosition.left}px`
                                }}
                                onClick={handleButtonClick}
                            >
                                <FontAwesomeIcon icon={faDownload}  />
                            </button>
                        )}
                    </div>
                </p>

            </div>

            {
                isOverflowing && (
                    <div className="read-more-button d-flex justify-content-center">
                        <Button variant='outline-primary' className="mt-3 mx-0" size="sm" onClick={toggleReadMore}>
                            {isExpanded ? Lang('VER_MENOS') : Lang('VER_MAS')}
                        </Button>
                    </div>
                )
            }

            {
                observaciones &&
                <p className="mb-0 mt-3 TicketMensaje__ItemObservaciones bg-light">
                    <span className="text-wrap">
                        <strong>{Lang('OBSERVACIONES')}</strong>: <span>{observaciones}</span>
                    </span>

                </p>
            }
        </article>
    )
};
