import langTheme from "../assetsTheme/language/CA"

const lang = {
    CA: {
        ...langTheme['CA'],
        REGRESAR_AL_PORTAL: "Tornar al portal",
        ESPANIOL: "Espanyol",
        CATALAN: "Català",
        CASTELLANO: 'Castellà',
        ADMINISTRACION: 'Administració',
        AREA_DE_FACTURACION: 'Àrea de Facturació',
        AREA_DE_ADMINISTRACION: `Àrea d'Administració`,
        AREA_DE_VISADOS: `Àrea de Visats`,
        FACTURACION: 'Facturació',
        GESTOR_DE_CONTENIDOS: 'Gestor de continguts',
        MIS_DATOS: 'Les meves dades',
        INTRANET_DE_ENGINYERS: `Intranet d'Enginyers`,
        
        PERSONAS: 'Persones',
        PERSONA: 'Persona',
        PERSONA_NUEVA: 'Persona nova',
        PARAMETROS: 'Paràmetres',
        USUARIOS: 'Usuaris',
        INICIO: 'Inici',
        PLANTILLAS: 'Plantilles',
        LISTA_DE_PERSONAS: 'Llista de persones',
        DATOS_PERSONALES: 'Dades personals',
        DATOS_GENERALES: 'Dades Generals',
        DATOS_COLEGIALES: 'Dades Col·legials',
        DATOS_PROFESIONALES: 'Dades Professionals',
        SEGUROS: 'Assegurances',
        DATOS_ECONOMICOS: 'Dades Econòmiques',
        COMUNICACIONES: 'Comunicacions',
        HISTORICOS: 'Històrics',
        ENTIDADES: 'Entitats',
        LISTA_DE_ENTIDADES: `Llista d'entitats`,
        CREAR_ENTIDAD: 'Crear Entitat',
        SEXO: 'Sexe',
        FECHA_NACIMIENTO: 'Data naixement',
        PAIS_NACIONALIDAD: 'País nacionalitat',
        ESTADO_CIVIL: 'Estat civil',
        DIRECCION_FISCAL: 'Adreça fiscal',
        CODIGO_POSTAL: 'Codi postal',
        PAIS: 'País',
        MUNICIPIO: 'Municipi',
        PROVINCIA: 'Província',
        TELEFONO_1: 'Telèfon 1',
        TELEFONO_2: 'Telèfon 2',
        EXTENSION_1: 'Extensió 1',
        EXTENSION_2: 'Extensió 2',
        TELEFONO_MOVIL: 'Telèfon mòbil',
        FAX: 'FAX',
        EMAIL: 'E-mail',
        OBSERVACIONES: 'Observacions',
        DATOS_DE_CONTACTO: 'Dades de contacte',
        LISTA_DE_USUARIOS: `Llista d'usuaris`,
        MENSAJE: 'Missatge',
        CODIGOS_ADICIONALES: 'Codis addicionals',
        DOCUMENTACION: 'Documentació',
        TIPO: 'Tipus',
        SEDE: 'Seu',
        FECHA_DE_ALTA: 'Data alta',
        FECHA_DE_BAJA: 'Data baixa',
        FECHA_INICIO: 'Data inici',
        FECHA_FIN: 'Data fi',
        NUM_COLEGIADO: 'Núm. Col·legiat',
        FECHA_SOLICITUD: 'Data sol·licitud',
        SERVICIOS: 'Serveis',
        CONDICIONES_DE_PAGO: 'Condicions de pagament',
        CONDICION_DE_PAGO: 'Condició de pagament',
        BANCOS: 'Bancs',
        LISTA_DE_BANCOS: 'Llista de Bancs',
        LISTA_DE_CONDICIONES_PAGO: 'Llista de Condicions de pagament',
        LISTA_DE_SERVICIOS: 'Llista de Serveis',
        LIMPIAR: 'Esborrar',
        BUSCAR: 'Cercar',
        NUEVA_GALERIA: 'Nova galeria',
        GALERIA: 'Galeria',
        GALERIAS: 'Galeries',
        SERVICIO: 'Servei',
        VOLVER: 'Tornar',
        NUEVO_SERVICIO_LINEA: 'Nou servei en línia',
        SERVICIO_LINEA: 'Servei en línia',
        HOMBRE: 'Home',
        MUJER: 'Dona',
        NUEVO_USUARIO: 'Nou usuari',
        LISTA_DE_PERFILES: 'Llista de perfils',
        NUEVO_PERFIL: 'Nou perfil',
        EDITAR_PERFIL: 'Editar perfil',
        PERFIL: 'Perfil',
        ALIAS: 'Àlies',
        LISTA_DE_MODULOS: 'Llista de mòduls',
        NUEVO_MODULO: 'Nou mòdul',
        EDITAR_MODULO: 'Editar Mòdul',
        MODULO: 'Mòdul',
        PLANTILLA_NUEVA: 'Plantilla nova',
        GUARDAR: 'Desar',
        NUEVO_CODIGO: 'Nou codi',
        EDITAR_CODIGOS: 'Edita codis',
        INSCRIPCIONES: 'Inscripcions',
        MIS_INSCRIPCIONES: 'Les meves inscripcions',
        ALQUILER_DE_APARATOS: `Lloguer d'Aparells`,
        VISADOS: 'Visats',
        VISADOR: 'Visador',
        GUIA_DE_PROFESIONALES: 'Guia de Professionals',
        LISTA_DE_NOTICIAS: 'Llista de Notícies',
        NUEVA_NOTICIA: 'Nova Notícia',
        PUBLICAR: 'Publicar',
        DESPUBLICAR: 'Despublicar',
        LISTA_DE_PATROCINADORES: 'Llista de Patrocinadors',
        NUEVO_PATROCINADOR: 'Nou Patrocinador',
        ELIMINAR: 'Eliminar',
        ALERT_URL_OBLIGATORIO: 'La URL és obligatòria.',
        ALERT_NOMBRE_OBLIGATORIO: 'El nom és obligatori.',
        SALIR: 'Sortir',
        CANCELAR: 'Cancel·la',
        MEDIOS_DE_PAGO: 'Mitjans de pagament',

        FACTURAS: 'Factures',
        COBROS: 'Cobraments',
        LINEAS_FACTURA_PENDIENTES: 'Línies de Factura pendents',
        CUOTAS: 'Quotes',
        CUOTA: 'Quota',

        LISTA_DE_REMESAS: 'Llista de Remesas',
        LISTA_DE_COBROS: 'Llista de cobraments',
        NUEVO_COBRO: 'Nou cobrament',
        EDITAR_COBRO: 'Editar Cobrament',
        NUEVA_PERSONA: 'Nova Persona',
        EXPORTAR_EXCEL: 'Exportar Excel',
        RELACION: 'Relació',
        PERMISO_EDICION: 'Permís edició',
        MOSTRAR_EN_REGISTRO_COLEGIADOS: 'Mostrar en el registre de col·legiats',
        CARGO: 'Càrrec',
        ENTIDAD: 'Entitat',
        NUEVA_ENTIDAD: 'Nova Entitat',
        EDITAR_ENTIDAD: 'Editar Entitat',
        PORCENTAJE_PARTICIPACION: 'Percentatge participació',
        DAR_DE_BAJA: 'Donar de baixa',
        PARRAFO_1_COLEGIADO_EMITIR_VISADOS: 'El col·legiat podrà emetre visats mitjançant aquesta entitat',
        NUEVA_RELACION: 'Nova Relació',
        EDITAR_RELACION: 'Editar Relació',
        LISTA_DE_RELACIONES: 'Llista de Relacions',
        LISTA_DE_TIPOSNOTICIA: 'Llista de Tipus Notícia',
        LISTA_DE_TIPOSCONTENIDO: 'Llista de Tipus Contingut',
        NUEVO_TIPONOTICIA: 'Nou Tipus Notícia',
        NUEVO_TIPOCONTENIDO: 'Nou Tipus Contingut',
        EDITAR_TIPONOTICIA: 'Editar Tipus Notícia',
        EDITAR_TIPOCONTENIDO: 'Editar Tipus Contingut',
        TIPOS_NOTICIA: 'Tipus Notícia',
        TIPO_NOTICIA: 'Tipus Notícia',
        TIPO_CONTENIDO: 'Tipus Contingut',
        ARCHIVOS_ADJUNTOS: 'Fitxers adjunts',
        NUEVO_FICHERO: 'Nou Fitxer',
        CUENTAS_BANCARIAS: 'Comptes Bancaris',
        CUENTA_BANCARIA: 'Compte Bancari',
        NUEVA_CUENTA_BANCARIA: 'Nou Compte bancari',
        EDITAR_CUENTA_BANCARIA: 'Editar Compte bancari',
        TITULAR_DE_CUENTA: 'Titular del compte',
        SELECCIONE_UNA_ENTIDAD: 'Seleccioneu una Entitat',
        SELECCIONE_UN_BANCO: 'Seleccioneu un Banc',
        SELECCIONE_UNA_FACTURA: 'Seleccioneu una Factura',
        SELECCIONE_UNA_PERSONA: 'Seleccioneu una Persona',
        SELECCIONE_UNA_REMESA: 'Seleccioneu una Remesa',
        ESTADO: 'Estat',
        FECHA_ESTADO: 'Data estat',
        NOMBRE: 'Nom',
        FACTURA: 'Factura',
        LINEAS_DE_FACTURA: 'Línies de factura',
        LINEAS: 'Línies',
        ABONAR: 'Abonar',
        NUEVA_LINEA_FACTURA: 'Nova Línia factura',
        EDITAR_LINEA_FACTURA: 'Editar Línia factura',
        ACTIVIDADES_PROFESIONALES: 'Activitats professionals',
        ACTIVIDAD_PROFESIONAL: 'Activitat Professional',
        NUEVA_ACTIVIDAD_PROFESIONAL: 'Nova Activitat Professional',
        EDITAR_ACTIVIDAD_PROFESIONAL: 'Editar Activitat Professional',

        INHABILITACIONES: 'Inhabilitacions',
        INHABILITACION: 'Inhabilitació',
        NUEVA_INHABILITACION: 'Nova Inhabilitació',
        EDITAR_INHABILITACION: 'Editar Inhabilitació',
        MENU_PRINCIPAL: 'Menú principal',

        DESCARGAR_PDFS_AGRUPADOS: 'Descarregar PDFs Agrupats',
        DESCARGAR_PDFS: 'Descarregar PDF(s)',
        DESCARGAR_PDF: 'Descarregar PDF',
        DESCARGANDO: 'Descarregant',
        ENVIAR_EMAILS: 'Enviar EMAIL(s)',
        ENVIAR_EMAIL: 'Enviar EMAIL',
        NOTIFICANDO: 'Notificant',
        REMESAS: 'Remeses',
        PARRAFO_SELECCIONAR_ALMENOS_1: 'Cal seleccionar almenys 1 resultat de cerca',
        PARRAFO_SELECCIONE_ALMENOS_1: 'Seleccioneu almenys 1 resultat de cerca',
        NUEVO_SEGURO: 'Nova assegurança',
        EDITAR_SEGURO: 'Edita assegurança',
        PARRAFO_OPERACION_EXITO: `L'operació s'ha fet amb èxit`,
        ALERT_OBLIGATORIO_CUENTA_PROPIA: 'El compte propi és obligatori.',
        ALERT_OBLIGATORIO_CUENTA_BANCARIA: 'El compte bancari és obligatori.',
        ALERT_OBLIGATORIO_CONDICION_PAGO: 'La Condició de pagament és obligatòria.',
        ALERT_OBLIGATORIO_FECHA_REGISTRO: 'La data registre és obligatòria.',
        ALERT_OBLIGATORIO_ESTADO: `L'Estat és obligatori.`,
        ALERT_OBLIGATORIO_FACTURA: 'La Factura és obligatòria.',

        DATOS_REQUERIDOS: 'Dades requerides',
        GENERAR_FICHERO: 'Generar Fitxer',
        PARRAFO_USARAN_ELEMENTOS_SELECCIONADOS: 'Es faran servir els elements seleccionats de la cerca actual',
        PARRAFO_USARAN_ELEMENTOS_BUSQUEDA: 'Es faran servir els elements de la cerca actual',
        PARRAFO_GENERARA_FICHERO_REMESA: 'Es generarà el fitxer per a la remesa',

        PARRAFO_USAR_BUSQUEDA_ACTUAL: 'Usar la cerca actual',
        PARRAFO_BUSCAR_REMESA_EXISTENTE: 'Buscar una remesa existent',
        DESCARGAR_EXCEL: 'Descarregar Excel',

        PARRAFO_SIN_MEDIOS_PAGO: 'El servei seleccionat no té mitjans de pagament',
        CUENTA_PROPIA: 'Compte propi',
        CUENTAS_PROPIAS: 'Comptes propis',

        NUM_COBROS: 'Num. Cobraments',

        ALERT_OBLIGATORIO_NIF: 'El NIF és obligatori.',
        ALERT_OBLIGATORIO_GENERICO: 'El Camp és obligatori.',
        ALERT_OPCION_DISPONIBLE_LUEGO_CREAR: 'Aquesta opció està disponible després de crear la persona',
        ALERT_OPCION_DISPONIBLE_LUEGO_CREAR_NOTICIA: 'Aquesta opció està disponible després de crear la notícia',
        ALERT_OPCION_DISPONIBLE_LUEGO_CREAR_APARATO: `Aquesta opció està disponible després de crear l'aparell`,
        ALERT_OPCION_DISPONIBLE_LUEGO_CREAR_OFERTA: `Aquesta opció està disponible després de crear l'oferta`,

        DADO_DE_BAJA: 'Dau de baixa',
        BAJA: 'Baixa',
        JUBILADO: 'JUBILAT',
        GRUPOS: 'Grups',
        NUEVO_GRUPO: 'Nou Grup',
        EDITAR_GRUPO: 'Editar Grup',

        SEDES: 'Seus',
        SEDE_CENTRAL: 'Seu central',

        NUEVA_SEDE: 'Nova Seu',
        EDITAR_SEDE: 'Edita Seu',
        FECHA_BAJA: 'Data baixa',
        FECHA_DE_REGISTRO: 'Data de registre',
        FECHA_VENCIMIENTO: 'Data de venciment',
        FECHA_COBRO: 'Data de cobrament',
        FECHA_PAGO: 'Data de pagament',

        NUEVA_CUOTA: 'Nova Quota',
        PORTADA: 'Portada',
        NOTICIAS: 'Notícies',
        PATROCINADORES: 'Patrocinadors',
        MULTIMEDIA: 'Multimèdia',

        AGREGAR: 'Afegir',
        PAGADORES: 'Pagadors',

        SELECCIONAR_ASOCIADOS: 'Seleccionar associats',
        DATOS_ADICIONALES: 'Dades addicionals',
        IR_A_LINEAS_FACTURA: 'Ir a Línies de factura',
        IR_A_FACTURAS: 'Ir a Factures',

        CREAR: 'Crear',
        NUEVA_FACTURA: 'Nova Factura',
        NUEVA_PROFORMA: 'Nova Proforma',
        VER_FACTURA: 'Veure Factura',

        PARRAFO_FACTURA_HA_SIDO_PAGADA: 'La factura ha estat pagada?',
        FINALIZAR: 'Finalitzar',
        NUEVA: 'Nova',
        EN_PROCESO: 'En procés',
        HISTORICO: 'Històric',
        NUEVA_TRAMITACION: 'Nova tramitació',
        LISTA_DE_VISADOS: 'Llista de Visats',
        LISTA_DE_VISADOS_RVD: 'Llista de Visats / RVD',
        VISADO: 'Visat',
        FECHA_ENTRADA: 'Data entrada',

        FORMACION: 'Formació',
        ESTUDIO: 'Estudi',
        ESPECIALIDAD: 'Especialitat',
        CENTRO: 'Centre',

        ANADIR_ESTUDIO: 'Afegir estudi',
        EDITAR_ESTUDIO: 'Editar estudi',
        TIPO_ESTUDIO: 'Tipus estudi',
        ANIO_FIN: 'Any fi',
        FOLIO: 'Foli',
        VOLUMEN: 'Volum',
        TIPO_DE_ENTIDAD: `Tipus d’entitat`,
        FECHA_JUBILACION: 'Data jubilació',
        JUBILACION: 'Jubilació',
        REACTIVAR: 'Reactivar',

        GRUPO: 'Grup',
        TITULO: 'Títol',
        SELECCIONAR_USUARIO: 'Seleccionar usuari',
        ASIGNAR_PERFIL: 'Assignar perfil',
        SELECCIONE_CERTIFICADO: 'Seleccioneu certificat',
        FIRMA: 'Signatura',
        CONTINUAR: 'Continuar',
        CREDENCIALES: 'Credencials',
        IR_AREA_CLIENTE: 'Anar a Àrea client',
        IR_AREA_ADMINISTRACION: 'Anar a Àrea Administració',

        CAMBIAR_CONTRASENIA: 'Canvia contrasenya',
        CAMBIAR: 'Canviar',
        CONTRASENIA: 'Contrasenya',
        REPETIR_CONTRASENIA: 'Repetir contrasenya',

        EN_REVISION: 'En revisió',
        APROBADOS: 'Aprovats',
        INCIDENCIAS: 'Incidències',
        ANULADOS: 'Anul·lats',

        CONTRASENIA_ACTUAL: 'Contrasenya actual',
        NUEVA_CONTRASENIA: 'Nova contrasenya',
        CONFIRMAR_NUEVA_CONTRASENIA: 'Confirmar nova contrasenya',
        TERCEROS: 'Tercers',
        DOCUMENTOS: 'Documents',
        DOCUMENTO: 'Document',
        REVISION_TECNICA: 'Revisió tècnica',
        REVISION_ECONOMICA: 'Revisió econòmica',
        TIPO_DOCUMENTO: 'Tipus document',
        NUEVO_DOCUMENTO: 'Nou document',
        EDITAR_DOCUMENTO: 'Editar document',
        FICHERO_ADJUNTO: 'Fitxer Adjunt',
        ADJUNTO: 'Adjunt',
        GUARDAR_POSICION: 'Desar posició',
        FIRMAR: 'Signar',
        CLIENTE: 'Client',
        CLIENTES: 'Clients',
        NUEVO_CLIENTE: 'Nou client',
        EDITAR_CLIENTE: 'Editar client',
        COLEGIADOS: 'Col·legiats',
        EMPLAZAMIENTO: 'Emplaçament',
        DIRECCION: 'Adreça',
        IMPORTE: 'Import',
        NUEVO_TIPO_DOC_TIPO_TRAB: 'Nou tipus document / tipus treball',
        NUEVO: 'Nou',
        EDITAR: 'Edit',
        ALERT_ENTIDAD_OBLIGATORIA: `L'entitat és obligatòria.`,
        FECHA_DE_PUBLICACION: 'Data de publicació',
        PUBLICADO: 'Publicat',
        PUBLICADOS: 'Publicats',
        DESCRIPCION: 'Descripció',
        DESCRIPCION_CORTA: 'Descripció corta',
        IMAGEN: 'Imatge',
        FECHA_REGISTRO: 'Data registre',
        PUBLICAR_DESDE: 'Publicar des',
        PUBLICAR_HASTA: 'Publicar fins',
        NOTICIA_EXTERNA: 'Notícia externa',
        DESCRIPCION_LARGA: 'Descripció llarga',
        ENLACE_EXTERNO_NOTICIA: 'Enllaç extern de la notícia',
        NOMBRE_COMERCIAL: 'Nom comercial',
        ENLACE: 'Enllaç',
        FECHA_DESDE: 'Data des',
        MANTENER_ORIGINAL: 'Mantenir original',
        RECORTAR_IMAGEN: 'Retallar imatge',
        TIPOS_DOCUMENTO: 'Tipus document',
        TIPOS_TRABAJO: 'Tipus treball',
        CADENA: 'Cadena',
        NUEVO_TIPO_DOCUMENTO: 'Nou tipus document',
        EDITAR_TIPO_DOCUMENTO: 'Editar tipus document',
        COD_TIPO_DOCUMENTO: 'Cod. Tipus document',
        ESTADISTICA: 'Estadística',

        NUEVO_TIPO_TRABAJO: 'Nou tipus treball',
        EDITAR_TIPO_TRABAJO: 'Editar tipus treball',
        COD_TIPO_TRABAJO: 'Cod. Tipus treball',
        SUBTITULO: 'Subtítol',
        PARRAFO_NO_HAY_DATOS_PARA_MOSTRAR: 'No hi han dades per mostrar',
        NUEVO_GRUPO_MULTIMEDIA: 'Nou grup multimèdia',
        EDITAR_GRUPO_MULTIMEDIA: 'Editar grup multimèdia',

        NUEVO_VIDEO: 'Nou vídeo',
        EDITAR_VIDEO: 'Editar vídeo',
        PAGINAS: 'Pàgines',
        NUEVA_PAGINA: 'Nova pàgina',
        AGREGAR_ITEM: 'Afegir ítem',
        AGREGAR_GALERIA: 'Afegir galeria',
        AGREGAR_NOTICIA: 'Afegeix notícia',
        AGREGAR_NOTICIAS:'Afegeix notícies',
        SELECCIONE_UNA_GALERIA: 'Seleccioneu una galeria',
        SELECCIONE_UN_ITEM: 'Seleccioneu un ítem',
        SELECCIONE_UNA_NOTICIA: 'Seleccioneu una notícia',
        SELECCIONE_UN_PATROCINADOR: 'Seleccioneu un patrocinador',
        ACCIONES: 'Accions',
        PUBLICAR_EN_WEB: 'Publicar a web',
        GUARDAR_CAMBIOS: 'Desar canvis',
        DERECHOS: 'Drets',
        LISTA_DE_DERECHOS: 'Llista de drets',
        SERVICIO_RVD: 'Servei RVD',
        SERVICIO_VISADO: 'Servei visat',
        FIRMADO: 'Signat',
        SELLO: 'Segell',
        SELECCIONE_UN_VISADO: 'Seleccioneu un Visat',
        BORRADORES: 'Esborranys',
        NUEVO_DERECHO: 'Nou dret',
        TIPO_TRABAJO: 'Tipus treball',
        AUTOMATICO: 'Automàtic',
        COMPROBANDO_VISADO: 'Comprovar visat',
        SOLICITAR_REVISION: 'Sol·licitar revisió',
        PARRAFO_SOLICITAR_REVISION: 'En sol·licitar la revisió no podrà modificar el visat',
        DESCARGAR: 'Descarregar',
        PARRAFO_INFORMACION_REVISION_FICHA_VISADO: 'El visat està en revisió, se us notificarà quan els tècnics hagin revisat el visat',
        PARRAFO_INFORMACION_APROBADO_FICHA_VISADO: 'El visat està aprovat, se us notificarà quan els tècnics hagin revisat el visat',
        COMPROBAR_Y_SOLICITAR: 'Comprovar i sol·licitar',
        ELIMINAR_CONTROL: 'Eliminar control',
        REQUISITO: 'Requisit',
        NO: 'No',
        SI: 'Si',
        NO_APLICA: 'No aplica',
        CONTROL: 'Control',
        SELECCIONE_CONTROL: 'Seleccioneu control',
        AGREGAR_CONTROL: 'Afegir control',
        NUEVA_INCIDENCIA: 'Nova incidència',
        EDITAR_INCIDENCIA: 'Editar incidència',
        INCIDENCIA: 'Incidència',
        DESCARTADA: 'Descartada',
        GESTIONAR_INCIDENCIAS: 'Gestionar incidències',
        FIRMA_PENDIENTE: 'Firma pendent',
        APROBAR: 'Aprovar',
        ANULAR: 'Anul·lar',
        CAMBIAR_ESTADO: 'Canviar estat',
        SI_ANULAR: 'Sí, anul·lar',
        SI_APROBAR: 'Sí, aprovar',
        SI_CAMBIAR_INCIDENCIA: 'Sí, canviar a incidència',
        PARRAFO_CONFIRMAR_ANULAR_VISADO: 'Esteu segur que voleu anul·lar el visat?',
        PARRAFO_CONFIRMAR_APROBAR_VISADO: 'Esteu segur que voleu aprovar el visat?',
        PARRAFO_CONFIRMAR_INCIDENCIA_VISADO: 'Esteu segur que voleu canviar a estat incidència el visat?',
        FECHA_SALIDA: 'Data sortida',
        PARRAFO_BUSQUEDA_ACTUAL: `S'usarà la cerca actual`,
        FECHA_SALIDA_DESDE: 'Data sortida des',
        FECHA_SALIDA_HASTA: 'Data sortida fins',
        NUM_ASOCIADO: 'Num. associat',
        CONVENIO: 'Conveni',
        COLEGIADO: 'Col·legiat',
        ELIMINAR_BORRADOR: 'Eliminar esborrany',
        LISTA_DE_FACTURAS: 'Llista de factures',
        LISTA_DE_PLANTILLAS: 'Llista de plantilles',
        TIPO_PLANTILLA: 'Tipus plantilla',
        ENVIAR: 'Enviar',
        COMUNICAR_INCIDENCIAS: 'Comunicar incidències',
        FECHA_ADJUNTO: 'Data adjunt',
        TIPO_ADJUNTO: 'Tipus adjunt',
        MOSTRAR_A_INTERESADO: 'Mostrar a interessat',
        NUEVA_DOCUMENTACION: 'Nova documentació',
        EDITAR_DOCUMENTACION: 'Editar documentació',
        FORMACION_EVENTOS: 'Formació / Esdeveniments',
        ENTIDAD_PAGADORA: 'Entitat pagadora',
        ENVIAR_BOLETIN: 'Enviar butlletí',
        ASUNTO: 'Assumpte',
        FECHA_CREACION: 'Data creació',
        LISTA_DE_COMUNICACIONES: 'Llista de Comunicacions',
        BOLETIN: 'Butlletí',
        REMITENTE_NOMBRE: 'Remitent nom',
        REMITENTE_EMAIL: 'Remitent email',
        FECHA_ENVIADO: 'Data enviat',
        LISTA_DE_DESTINATARIOS: 'Llista de Destinataris',
        PLANTILLA: 'Plantilla',
        EVENTO: 'Esdeveniment',
        LISTA_DE_EVENTOS: `Llista d'esdeveniments`,
        VER_VISADO_RELACIONADO: 'Veure visat relacionat',
        TRAMITACION: 'Tramitació',
        NUMERO_VISADO: 'Num. visat',
        FECHA_VISADO: 'Data visat',
        NUMERO_ENTRADA: 'Num. entrada',
        TIPOS_CLASIFICACION: 'Tipus Classificació',
        CODIGO: 'Codi',
        EDITAR_TIPOCLASIFICACION: 'Editar Tipus Classificació',
        NUEVO_TIPOCLASIFICACION: 'Nou Tipus Classificació',
        LISTA_DE_TIPOSNOTICIACLASIFICACION: 'Llista de Tipus de Notícies Classificació',
        CLASIFICACION: 'Classificació',
        PUBLICAR_EN: 'Publicar a',
        FECHA_HASTA: 'Data fins',
        TIPOS_DE_ESPECIALIDADES: `Tipus d'especialitats`,
        NUEVA_ESPECIALIDAD: 'Nova especialitat',
        EDITAR_ESPECIALIDAD: 'Editar especialitat',
        EVENTOS: 'Esdeveniments',
        TIPO_EVENTO: `Tipus d'esdeveniment`,
        DESCRIPCION_IDIOMA: 'Descripció idioma',
        FECHAS: 'Dates',
        TARIFAS: 'Tarifes',
        AREA_DE_INSCRIPCIONES: 'Àrea d´inscripcions',
        NUEVO_EVENTO: 'Nou esdeveniment',
        FECHA_INICIO_INSCRIPCION: 'Data inici inscripció',
        FECHA_FIN_INSCRIPCION: 'Data fi inscripció',
        AFORO: 'Aforament',
        GRATUITO: 'Gratuït',
        CREDITOS: 'Crèdits',
        NOMBRE_DEL_EVENTO: `Nom de l'esdeveniment`,
        INFORMACION_ADICIONAL: 'Informació addicional',
        DIRIGIDO_A: 'Dirigit a',
        LUGAR: 'Lloc',
        OBJETIVO: 'Objectiu',
        PONENTES: 'Ponents',
        FECHA_EVENTO: 'Data esdeveniment',
        HORA_INICIO: 'Hora inici',
        HORA_FIN: 'Hora fi',
        HORARIO_DEL_EVENTO: `Horari de l'esdeveniment`,
        HORARIOS: 'Horaris',
        NOMBRE_DOCUMENTO: 'Nom document',
        NUEVA_TARIFA: 'Nova tarifa',
        EDITAR_TARIFA: 'Editar tarifa',
        TARIFA_APLICADA_A: 'Tarifa aplicada a',
        PRECIO: 'Preu',
        TARIFA: 'Tarifa',
        FECHA_FACTURA: 'Data Factura',
        IMAGEN_PORTADA: 'Imatge portada',
        ALERT_OPCION_DISPONIBLE_LUEGO_CREAR_EVENTO: `Aquesta opció està disponible després de crear l'esdeveniment`,
        VER_CALENDARIO: 'Veure calendari',
        EDITAR_TIPO_ENTIDAD: 'Editar tipus entitat',
        NUEVO_TIPO_ENTIDAD: 'Nou tipus entitat',
        FECHA: 'Data',
        NUEVA_INSCRIPCION: 'Nova inscripció',
        EDITAR_INSCRIPCION: 'Editar inscripció',
        DATOS_DE_LA_PERSONA: 'Dades de la persona',
        OTROS_DATOS: 'Altres dades',
        VER_FICHA_PERSONA: 'Veure fitxa persona',
        VER_MAS: 'Veure més',
        REENVIAR_PENDIENTE: 'Reenviar pendent',
        DESTINATARIO_EMAIL: 'Destinatari email',
        CONTENIDOS: 'Continguts',
        LISTA_DE_CONTENIDOS: 'Llista de continguts',
        NUEVO_CONTENIDO: 'Nou contingut',
        CONTENIDO_EXTERNO: 'Contingut extern',
        DIAS_FESTIVOS: 'Dies festius',
        DIA_FESTIVO: 'Dia festiu',
        APARATOS: 'Aparells',
        NUEVO_APARATO: 'Nou aparell',
        MODELO: 'Model',
        ALQUILERES: 'Lloguers',
        APARATO: 'Aparell',
        NUEVO_ALQUILER: 'Nou lloguer',
        ALQUILER: 'Lloguer',
        TEL: 'Tel.',
        NUM: 'Núm.',
        DIAS_ALQUILER: 'Dies lloguer',
        CONDICIONES: 'Condicions',
        BOLSA_DE_TRABAJO: 'Borsa de treball',
        NUEVO_OFERTA: 'Nova oferta',
        AREA_BOLSA_TRABAJO: 'Àrea Borsa de treball',
        OFERTAS: 'Ofertes',
        FECHA_VISADO_DESDE: 'Data visat des de',
        FECHA_VISADO_HASTA: 'Data visat fins',
        ENTIDADES_EMISORAS: 'Entitats emissores',
        NUEVA_ENTIDAD_EMISORA: 'Nova entitat emissora',
        EDITAR_ENTIDAD_EMISORA: 'Editar entitat emissora',
        LISTA_DE_ENTIDADES_EMISORAS: `Llista d'entitats emissores`,

        NUEVA_CUENTA_PROPIA: 'Nova compte pròpia',
        EDITAR_CUENTA_PROPIA: 'Editar compte propi',
        EFECTIVO: 'Efectiu',
        MIS_FACTURAS: 'Les meves factures',
        IVAS: 'IVAs',
        NUEVO_IVA: 'Nou IVA',
        EDITAR_IVA: 'Editar IVA',
        LISTA_DE_IVAS: `Llista d'IVAs`,
        CONTROLES: 'Controls',
        LISTA_CONTROLES: 'Llista de controls',
        CLASE_CONTROL: 'Classe control',
        NUEVO_CONTROL: 'Nou control',
        EDITAR_CONTROL: 'Editar control',
        TIPO_CONTROL: 'Tipus control',
        LISTA_TIPOS_CONTROL: 'Llista tipus control',
        LISTA_SECCIONES: 'Llista seccions',
        NUEVA_SECCION: 'Nova secció',
        TIPOS_CONTROLES: 'Tipus controls',
        NUEVO_TIPO_CONTROL: 'Nou tipus control',
        LISTA_CONTROL_REQUISITOS: 'Llista control requisits',
        NUEVO_CONTROL_REQUISITO: 'Nou control requisit',
        EDITAR_CONTROL_REQUISITO: 'Editar control requisit',
        PLANTILLAS_CERTIFICADOS: 'Plantilles certificats',
        NUEVA_PLANTILLA: 'Nova plantilla',
        NOMBRE_PLANTILLA: 'Nom plantilla',
        PLANTILLA_CERTIFICADO: 'Plantilla certificat',
        CERTIFICADO: 'Certificat',
        REVERSO_PLANTILLA: 'Revers plantilla',
        SUBIR: 'Pujar',
        DESCARGAR_PLANTILLA: 'Descarregar plantilla',
        TIENDA: 'Botiga',
        CUENTA_CONTABLE: 'Compte comptable',
        CUENTAS_CONTABLES: 'Comptes comptables',
        CUENTA_CONTABLE_SOPORTADO: 'Compte comptable suportat',
        CUENTA_CONTABLE_REPERCUTIDO: 'Compte comptable repercutit',
        DATOS_CONTABILIDAD: 'Dades comptabilitat',
        NUEVA_CUENTA_CONTABLE: 'Nou compte comptable',
        EDITAR_CUENTA_CONTABLE: 'Editar compte comptable',

        TIPO_CUENTA_CONTABLE: 'Tipus compte comptable',
        ENTIDAD_PROPIA: 'Entitat pròpia',
        FACTURAS_PROVEEDOR: 'Factures proveïdor',
        PROVEEDOR: 'Proveïdor',
        NUMERO_FACTURA: 'Número factura',
        EXPORTAR_A_CONTABILIDAD: 'Exportar a comptabilitat',
        PRECIO_POR_DIA: 'Preu per dia',
        DIA: 'Dia',
        ESTADO_OFERTA: 'Estat oferta',
        ESTADOS_OFERTA: 'Estats oferta',
        NUEVO_ESTADO_OFERTA: 'Nou estat oferta',
        EDITAR_ESTADO_OFERTA: 'Editar estat oferta',
        ESTADOS_PERSONA: 'Estats persona',
        NUEVO_ESTADO_PERSONA: 'Nou estat persona',
        EDITAR_ESTADO_PERSONA: 'Editar estat persona',
        FUNCIONES: 'Funcions',
        NUEVA_FUNCION: 'Nova funció',
        EDITAR_FUNCION: 'Editar funció',
        NUEVO_HORARIO: 'Nou horari',
        EDITAR_HORARIO: 'Editar horari',
        NIVELES_IDIOMA: 'Nivells idioma',
        EDITAR_NIVEL_IDIOMA: 'Editar nivell idioma',
        NUEVO_NIVEL_IDIOMA: 'Nou nivell idioma',
        NIVEL_IDIOMA: 'Nivell idioma',
        ORDEN_IDIOMA: 'Ordre idioma',
        SALARIOS: 'Salaris',
        NUEVO_SALARIO: 'Nou salari',
        EDITAR_SALARIO: 'Editar salari',
        TEXTO: 'Text',
        CANTIDAD: 'Quantitat',
        TIPOS_CONTRATO: 'Tipus contracte',
        NUEVO_TIPO_CONTRATO: 'Nou tipus contracte',
        EDITAR_TIPO_CONTRATO: 'Editar tipus contracte',
        OFERTA: 'Oferta',
        HORARIO: 'Horari',
        CATEGORIA: 'Categoria',
        LISTA_DE_OFERTAS: `Llista d'ofertes`,
        COBROS_PROVEEDOR: 'Cobraments proveïdor',
        NUEVO_IRPF: 'Nou IRPF',
        LISTA_IRPFS: `Llista d'IRPFs`,
        EDITAR_IRPF: 'Editar IRPF',
        TIPO_GRUPO: 'Tipus grup',
        EDITAR_TIPO_GRUPO: 'Editar tipus grup',
        NUEVO_TIPO_GRUPO: 'Nou tipus grup',
        TIPOS_GRUPO: 'Tipus grup',
        NOTICIA: 'Notícia',
        SELECCIONE_UNA_INSCRIPCION: 'Seleccioneu una inscripció',
        LISTA_DE_INSCRIPCIONES: `Llista d'inscripcions`,
        CURRICULUM: 'Currículum',
        NIF: 'NIF',
        MOVIL: 'Mòbil',
        APELLIDOS: 'Cognoms',
        AGREGAR_PERSONA: 'Afegir persona',
        TIPO_CONTRATO: 'Tipus contracte',
        NUM_VACANTES: 'Núm. vacants',
        SALARIO: 'Salari',
        FECHA_PUBLICACION_INICIO: 'Data publicació inici',
        FECHA_PUBLICACION_FIN: 'Data publicació fi',
        IDIOMA: 'Idioma',
        AGREGAR_NIVEL_IDIOMA: 'Afegir nivell idioma',
        IDIOMAS: 'Idiomes',
        AGREGAR_ESTUDIO: 'Afegir estudi',
        INTERES: 'Interès',
        EXPERIENCIA: 'Experiència',
        FUNCION: 'Funció',
        LISTA_DE_PAGOS: 'Llista de pagaments',
        PAGO: 'Pagament',
        PAGOS: 'Pagaments',
        LISTA_FACTURAS_COMPRA: 'Llista de factures compra',
        NUEVO_PAGO: 'Nou pagament',
        EDITAR_PAGO: 'Editar pagament',
        NUEVA_FACTURA_COMPRA: 'Nova factura compra',
        PRECIO_UNITARIO: 'Preu unitari',
        ESTUDIOS: 'Estudis',
        OFERTAS_EMPLEOS: 'Ofertes llocs de treball',
        NOMBRE_OFERTA: `Nom de l'oferta`,
        INTERES_OFERTA: `Interès en l'oferta`,
        NOTIFICAR_EMPRESA: `Notificar a l'empresa`,
        MARCAR_DOCUMENTO_PREFERIDO: 'Marcar document preferit',
        CV_DEFECTO: 'CV per defecte',
        HABILITAR_OFERTAS: 'Habilitar ofertes',
        RECIBIR_NOTIFICACIONES_EMAIL: 'Rebre notificacions Email',
        ELIMINAR_INSCRIPCION: 'Eliminar inscripció',
        TABLON_OFERTAS: 'Tauló ofertes',
        MIS_OFERTAS: 'Les meves ofertes',
        VISIBLE_POR_EMPRESA: 'Visible per empresa',
        CURRICULUMS: 'Currículums',
        AGREGAR_CURRICULUM: 'Afegiu el currículum',
        PREFERIDO: 'Preferit',
        EDITAR_CURRICULUM: 'Editar curriculum',
        INTERESADO: 'Interessat',
        FICHA_PERSONA: 'Fitxa persona',
        NO_EXISTE_CV_ASOCIADO_A_OFERTA: 'No hi ha un CV associat a aquesta oferta',
        VER_MAS_INFORMACION_PERSONA: 'Veure més informació de la persona',
        BOLSA_DE_TRABAJO_EMPRESA: 'Borsa de treball empresa',
        BUSCADOR: 'Cercador',
        NOTIFICADO: 'Notificat',
        BUSCADOR_PERSONAS: 'Cercador de persones',
        LIMPIAR_TODO: 'Esborrar-ho tot',
        INSCRIBIR_PERSONAS: 'Inscriure persones',
        PERFIL_CANDIDATO: 'Perfil candidat',
        SE_HAN_ENCONTRADO: `S'han trobat`,
        RESULTADOS: 'Resultats',
        VISIBLE_EMPRESA: 'Visible empresa',
        REQUERIR_TODOS_IDIOMAS: 'Requerir tots els idiomes',
        REQUERIR_TODOS_ESTUDIOS: 'Requerir tots els estudis',
        REQUERIR_TODAS_ESPECIALIDADES: 'Requerir totes les especialitats',
        REQUERIR_TODAS_EXPERIENCIAS: 'Requerir totes les experiències',
        REQUERIR_TODOS_INTERESES: 'Requerir tots els interessos',
        IR_A_FICHA_PERSONA: 'Anar a la fitxa de la persona',
        NOTIFICAR_ENCARGADOS: 'Notificar encarregats',
        CREAR_PLANTILLA: 'Crear Plantilla',
        SI_CAMBIAR_ESTADO: 'Sí, canviar estat',
        DATOS_ALQUILER: 'Dades lloguer',
        FECHA_RECOGIDA_DESDE: 'Data recollida des',
        FECHA_RECOGIDA_HASTA: 'Data recollida fins',
        FECHA_DEVOLUCION_DESDE: 'Data devolució des',
        FECHA_DEVOLUCION_HASTA: 'Data devolució fins',
        NOMBRE_ADJUNTO: 'Nom adjunt',
        PUEDE_SELECCIONAR_MULTIPLES_FICHEROS: 'Podeu seleccionar múltiples fitxers',
        IMPORTE_TOTAL: 'Import total',
        VER_ENTIDAD: 'Veure entitat',
        VER_PERSONA: 'Veure persona',
        PARRAFO_MOSTRAR_FACTURAS_PENDIENTES: 'Només es mostren factures pendents',
        AGREGAR_UNA_IMAGEN: 'Afegeix una imatge',
        LISTA_DE_PERSONAS_ENTIDADES: 'Llista de Persones - Entitats',
        LISTA_DE_ENTIDADES_PERSONAS: `Llista d'entitats - persones`,
        N_VISADOS: 'N. Visats',
        PENDIENTE: 'Pendent',
        COBRADO: 'Cobrat',
        IMPAGADO: 'Impagat',
        PAGADO: 'Pagat',
        PRECOLEGIADO: 'Precol·legiat',
        COLEGIADO_EXTERNO: 'Col·legiat extern',
        NO_ASOCIADO: 'No associat',
        NO_COLEGIADO: 'No col·legiat',
        TODOS: 'Tots',
        VIGENTE: 'Vigent',
        PENDIENTES: 'Pendents',
        COBRADAS: 'Cobrades',
        OTROS: 'Altres',
        PROFORMAS: 'Proformes',
        ABONO: 'Abonament',
        RESPONSABILIDAD_CIVIL: 'Responsabilitat civil',
        CASADOA: 'Casat/da',
        SOLTEROA: 'Solter/a',
        DIVORCIADOA: 'Divorciat/da',
        VIUDOA: 'Vidu/a',
        CONVIVIENTE: 'Convivent',
        SEPARADOA: 'Separat/da',
        ANULADO: 'Anul·lat',
        BORRADOR: 'Esborrany',
        EN_INCIDENCIA: 'En incidència',
        DOCUMENTO_A_SELLAR: 'Document a segellar',
        DOCUMENTO_DE_ADMINISTRACION: `Document d'administració`,
        PASAPORTE: 'Passaport',
        CIF_EXTRANJERO: 'CIF Estranger',
        SOPORTE_DIGITAL: 'Suport digital',
        SOPORTE_PAPEL: 'Suport paper',
        TRAMITE: 'Tràmit',
        FINALIZADO: 'Finalitzat',
        EXTERNO: 'Extern',
        GENERAL: 'General',
        LISTA_DE_ESPERA: `Llista d'espera`,
        PENDIENTE_DE_PAGO: 'Pendent de pagament',
        ANULADA: 'Anul·lada',
        INSCRIPCION: 'Inscripció',
        TRANSFERENCIA: 'Transferència',
        REMESA: 'Remesa',
        CAROUSEL: 'Carousel',
        MIXTO: 'Mixt',
        VENTA: 'Venda',
        COMPRA: 'Compra',
        ENTIDAD_CLIENTE: 'Entitat client',
        ENTIDAD_PROVEEDOR: 'Entitat proveïdor',
        PERSONA_CLIENTE: 'Persona client',
        PERSONA_PROVEEDOR: 'Persona proveïdor',
        EXPORTADOS: 'Exportats',
        NO_EXPORTADOS: 'No exportats',
        PENDIENTE_DE_RECOJO: 'Pendent de recull',
        PENDIENTE_DE_ENTREGA: 'Pendent de lliurament',
        ACTIVOS: 'Actius',
        EMPRESA: 'Empresa',
        IMPORTE_PRESUPUESTO: 'Import Pressupost',
        NUM_FACTURA: 'Nº Factura',
        ABONA: 'Abona',
        BANCO: 'Banc',
        COD_BANCO: 'Cod. Banc',
        TOTAL: 'Total',
        ELEMENTOS: 'Elements',
        INICIAR_SESION: 'Iniciar sessió',
        OLVIDO_SU_CLAVE: 'Ha oblidat la seva clau?',
        ACCEDER: 'Accedir',
        USUARIOA: 'Usuari/a',
        PARRAFO_RECUPERAR_CLAVE: `Si esteu registrat i heu oblidat el vostre nom d'usuari/ària i/o clau, o aquesta ha quedat bloquejada per successius intents fallits, introduïu els dades requerits.`,
        DESTINATARIO_NOMBRE: 'Destinatari nom',
        REENVIANDO_PENDIENTES: 'Reenviando pendientes',
        LISTA_DE_ASOCIADOS: `Llista d'associats`,
        TRAMITADOR: 'Tramitador',
        IMPORTE_CUOTA: 'Import Quota',
        PROFORMA: 'Proforma',
        PARRAFO_FECHA_REGISTRO_CUOTA: `La data de registre ha d'estar al rang de generació de la quota`,
        CONCEPTO: 'Concepte',
        CANTIDAD_TOTAL: 'Quantitat total',
        CANTIDAD_ENVIADAS: 'Quantitat enviades',
        IMPORTE_BASE: 'Import BASE',
        IMPORTE_IVA: 'Import IVA',
        IMPORTE_IRPF: 'Import IRPF',
        TITULAR: 'Titular',
        CUENTA_CORRIENTE: 'Compte Corrent',
        TELEFONO: 'Telèfon',
        CLASE_SEGURO: `Classe d'assegurança`,
        FECHA_CADUCIDAD: 'Data caducitat',
        ASEGURADORA: 'Asseguradora',
        MODALIDAD_SEGURO: 'Modalitat assegurança',
        ABONANTE: 'Abonante',
        TIPO_SERVICIO: 'Tipus servei',
        SERIE: 'Sèrie',
        TIPO_NOTIFICACION: 'Tipus notificació',
        USUARIO: 'Usuari',
        PERFILES: 'Perfils',
        NUMERO_DE_PEDIDO: 'Número de comanda',
        COBRO: 'Cobrament',
        FECHA_REGISTRO_DESDE: 'Data registre des',
        FECHA_REGISTRO_HASTA: 'Data registre fins',
        REFERENCIA: 'Referència',
        COLEGIO: 'Col·legi',
        MOTIVO_DE_BAJA: 'Motiu de baixa',
        IMPORTE_POR_DEFECTO: 'Import per defecte',
        GESTIONAR_REMESAS: 'Gestionar remeses',
        CREAR_REMESA: 'Crear remesa',
        PARRAFO_FILTRAR_PRIMERO_CUENTA_PROPIA: 'Ha de filtrar primer per compte propi',
        CREAR_FICHERO_SEPA_XML_COR1: 'Crear fitxer SEPA XML COR1',
        FICHERO_SEPA_XML_COR1: 'fitxer SEPA XML COR1',
        CREAR_FICHERO_SEPA_XML_CORE: 'Crear fitxer SEPA XML CORE',
        FICHERO_SEPA_XML_CORE: 'fitxer SEPA XML CORE',
        NUM_VISADO: 'Num. visat',
        NOMBRE_COLEGIADO: 'Nom col·legiat',
        SUFIJO_REMESA: 'Sufix remesa',
        DESCRIPCION_REMESA: 'Descripció remesa',
        FECHA_SOPORTE: 'Data suport',
        IMPORTE_DESCUENTO: 'Import descompte',
        FECHA_ALTA_DESDE: 'Data alta des',
        FECHA_ALTA_HASTA: 'Data alta fins',
        FECHA_BAJA_DESDE: 'Data baixa des',
        FECHA_BAJA_HASTA: 'Data baixa fins',
        NUEVA_IMAGEN: 'Nova imatge',
        CONTENIDO_COMUNICACION: 'Contingut comunicació',
        ENVIADO_DESDE: 'Enviat des de',
        ENVIADO_HASTA: 'Enviat fins',
        FECHA_FIRMA: 'Data signa',
        FECHA_CONSTITUCION: 'Data constitució',
        PARRAFO_SELECCION_SEDE_DATOS: 'Seleccioneu una seu per obtenir les vostres dades',
        NOMBRE_POLIZA: 'Nom pòlissa',
        CLASE: 'Classe',
        NOMBRE_MUTUALISTA: 'Nom mutualista',
        TIPO_RELACION: 'Tipus relació',
        COD_RELACION: 'Cod. relació',
        ESTADO_PERSONA: 'Estat persona',
        TIPO_PERSONA: 'Tipus persona',
        MOTIVO_EXENTO_CUOTA: 'Motiu exempt de quota',
        APELLIDO: 'Cognom',
        USUARIO_WEB: 'Usuari web',
        FECHA_RESGUARDO: 'Data resguard',
        FECHA_EXPEDICION: 'Data expedició',
        FECHA_HOMOLOGACION: 'Data homologació',
        CODIGO_DEL_CENTRO: 'Codi del centre',
        NUM_REGISTRO_CENTRO: 'Núm. Registre centre',
        NUM_REGISTRO_MINISTERIO: 'Núm. Registre ministeri',
        DESCRIPCION_DE_LA_PLANTILLA: 'Descripció de la plantilla',
        CODIGO_TIPO_NOTIFICACION: 'Codi tipus notificació',
        GESTOR_DE_PERFILES: 'Gestor de perfils',
        GESTOR_DE_MODULOS: 'Gestor de mòduls',
        NUM_EXPEDIENTE: 'Núm. expedient',
        IDIOMA_VISADO: 'Idioma visat',
        TIPO_CONDICION_DE_PAGO: 'Tipus condició de pagament',
        POR_DEFECTO: 'Per defecte',
        GENERAR_FACTURAS_AGRUPADAS: 'Generar factures agrupades',
        CAMBIAR_CONDICION_DE_PAGO: 'Canviar condició de pagament',
        ELIMINAR_LINEA_DE_FACTURA: 'Eliminar línia de factura',
        NUM_PROFORMA: 'Nº Proforma',
        PRESUPUESTO: 'Pressupost',
        BASE_IMPONIBLE: 'Base imposable',
        CONFIRMAR_CREAR_REMESA: 'Confirmar crear remesa',
        CONDICION_DE_COBRO: 'Condició de cobrament',
        ERRORES: 'Errors',
        OBSERVACIONES_DEL_CLIENTE: 'Observacions del client',
        OBSERVACIONES_USO_INTERNO: 'Observacions: (Ús intern)',
        GENERAR_FACTURA: 'Generar factura',
        NOTIFICAR_POR_EMAIL: 'Notificar per email',
        NOMBRE_DEL_FICHERO: 'Nom del fitxer',
        EXPORTADOS_A_CONTA: 'Exportats a compta.',
        FECHA_CUOTA_DESDE: 'Data quota des',
        HASTA_EL: 'Fins al',
        CUOTA_A_GENERAR: 'Quota a generar',
        MOTIVO_RECTIFICACION: 'Motiu rectificació',
        TIPO_PROCESOS_CONVENIO: 'Tipus processos conveni',
        CODIGO_BANCO: 'Codi banc',
        TIPO_PASARELA: 'Tipus passarel·la',
        ENTIDAD_EMISORA: 'Entitat emissora',
        SERIE_RECTIFICATIVA: 'Sèrie rectificativa',
        PLANTILLA_FACTURA: 'Plantilla factura',
        ENTIDAD_PREDETERMINADA: 'Entitat predeterminada',
        PORCENTAJE: 'Percentatge',
        GENERAR: 'Generar',
        CODIGO_APLICACION: 'Codi aplicació',
        COD_TIPO_CUOTA: 'Codi. Tipus quota',
        FECHA_INICIO_PATROCINIO: 'Data inici patrocini',
        FECHA_FIN_PATROCINIO: 'Data fi patrocini',
        SERVICIO_DE_LINEA: 'Servei de línia',
        PRECIO_ORIGINAL: 'Preu original',
        PORCENTAJE_DESCUENTO: 'Percentatge descompte',
        TRAMITACION_RELACIONADA: 'Tramitació relacionada',
        PARRAFO_SELECCIONE_APARATO_MEDIDA: `Seleccioneu l'aparell de mesura`,
        DIAS_MINIMOS: 'Dies mínims',
        DIAS_MAXIMOS: 'Dies màxims',
        PARRAFO_ESPECIFICA_CONVENIO_APLICAR: 'Especifica el conveni a aplicar',
        PUBLICADO_EN: 'Publicat a',
        NOMBRE_DE_LA_EMPRESA: `Nom de l'empresa`,
        IMAGEN_PRINCIPAL: 'Imatge principal',
        SALON_PRINCIPAL: 'Saló principal',
        SALA: 'Sala',
        PARRAFO_SELECCIONE_ALMENOS_UN_BLOQUE_BUSQUEDA: 'Seleccioneu almenys un bloc de cerca',
        BUSCAR_PERSONAS: 'Buscar persones',
        EMAILS_NO_ENVIADOS: 'E-mails no enviats',
        MODIFICAR_BUSQUEDA: 'Modificar cerca',
        RESUMEN_DE_BUSQUEDA: 'Resum de cerca',
        ES_VISADOR: 'És visador',
        EXPERIENCIA_EN: 'Experiència a:',
        YA_ESTA_INSCRITO_OFERTA: `Ja està inscrit a l'oferta`,
        VER_OFERTA_PUBLICADA: `Vegeu l'oferta publicada`,
        IR_A_INSCRIBIRME_OFERTA: `Anar a inscriure'm a l'oferta`,
        FICHA_DE_LA_OFERTA: `Fitxa de l'oferta`,
        EXCLUIR_ERRORES: 'Excloure errors',
        RUTA_EMPRESA: 'Ruta empresa',
        PREDETERMINADA: 'Per defecte',
        VALIDACION_PERSONA_OBLIGATORIA: 'La persona és obligatòria',
        VALIDACION_ENTIDAD_OBLIGATORIA: `L'entitat és obligatòria`,
        CUALQUIER_ACREDEDOR_SERVICIO: 'Qualsevol creditor i servei',
        CARGAR_ESTA_EXPLICA: 'Carregar en aquesta explica',
        MANDATO: 'Mandat',
        PREDEFINIDA: 'Predefinida',
        EXENTO: 'Exempt',
        SIN_ENLACE: 'Sense Enllaç',
        ENLACE_INTERNO: 'Enllaç Intern',
        ENLACE_EXTERNO: 'Enllaç Extern',
        SUBIR_NUEVO_CV: 'Pujar nou CV',
        SELECCIONAR_CV_EXISTENTE: 'Seleccionar CV existent',
        ACTUAL: 'Actual',
        EJERCICIO_ANTERIOR: 'Exercici anterior',
        PARRAFO_SEGURO_SACAR_HISTORICO: `Segur que voleu treure d'històric?`,
        NOTIFICAR_CAMBIO: 'Notificar canvi',
        PARRAFO_SEGURO_PASAR_HISTORICO: 'Segur que voleu passar a històric?',
        COLEGIO_PROFESIONAL: 'Col·legi professional',
        PARRAFO_CLIC_APAREZCAN_NOTICIAS: 'Feu clic on voleu que apareguin les notícies',
        CONTACTO_PRINCIPAL: 'Contacte principal',
        FOTOGRAFIA: 'Fotografía',
        TIPO_CUOTA: 'Tipus quota',
        RELACION_PROFESIONAL: 'Relació professional',
        INDICAR_MODULOS_ASOCIADOS: 'Indicar Mòduls associats',
        EDITAR_PERFILES: 'Edita perfils',
        INDICAR_PERFIL: 'Indicar perfil',
        INDICAR_PERFIL_ASOCIADO: 'Indicar perfil associat',
        MENCIONAR_NOMBRE_EMPRESA: `Esmentar el Nom de l'Empresa`,
        ES_CUOTA: 'És quota',
        PRIVADO: 'Privat',
        PUBLICAR_WEB: 'Publicar web',
        RUTA: 'Ruta',
        CARGAR_DOCUMENTO: 'Carregar document',
        BUSCAR_TIPO_TRABAJO: 'Cercar Tipus Treball',
        AUTOCARGAR_DATOS_CLIENTE: 'Autocargar dades client',
        FIRMA_DE_DOCUMENTOS: 'Signatura de documents',
        PARRAFO_DESCARGAR_INSTALAR_PLUGIN: `Per poder continuar amb el procés de signatures, si us plau descarregueu i instal·leu el plugin`,
        PLUGIN_DE_FIRMA: 'Plugin de Firma',
        NO_NECESARIO_DESCARGAR_DOCUMENTOS: 'No cal descarregar els documents per signar-los',
        PUEDES_FIRMAR_MULTIPLES_DOCUEMNTOS: 'Pots signar múltiples documents alhora',
        PUEDES_ANADIR_MARCAS: `Podràs afegir marques d'aigua de manera personalitzada`,
        AGILIZA_PROCESO_FIRMA: 'Agilitza el procés de signatura',
        DESCARGAR_INSTALAR_PLUGIN: 'Descarregar i instal·lar Plugin',
        COMO_INSTALAR_PLUGIN: 'Com instal·lar el plugin?',
        BAJAR_GUIA_INSTALACION: `Si teniu problemes sobre el connector, baixeu la guia d'instal·lació`,
        GUIA_DE_INSTALACION: `Guia d'instal·lació`,
        PARRAFO_DESCARGAR_NAVEGADORES: 'El vostre navegador no és compatible amb el Plugin, si us plau utilitzeu un dels següents navegadors per continuar amb el procés de signatura',
        VER_OTRAS_GUIAS: 'Veure altres guies',
        CONFIGURACION_DEL_SELLO: 'Configuració del segell',
        SELECCIONE_OPCION_EDICION: `Seleccioneu una opció d'edició`,
        COLOCAR_SELLO_UNA_PAGINA: 'Col·locar segell en una pàgina',
        PARRAFO_PAGINA_ARRASTRE_SELLO: 'Un cop estigui a la pàgina seleccionada arrossegueu la imatge del segell fins on es vol col·locar',
        PAGINA_SELECCIONADA: 'Pàgina seleccionada',
        PAG: 'Pàg.',
        COLOCAR_SELLO_CADA_PAGINA: 'Col·locar segell a cada pàgina',
        PARRAFO_MUEVASE_INDICAR_SELLO: 'Moveu-vos per les diferents pàgines del document per indicar la posició del segell',
        ESTABLECER_PAGINA_REFERENCIA: 'Establir Pàgina de Referència',
        PARRAFO_PAGINA_NO_INDIQUE_SELLO: `Aquesta pàgina s'usarà per a aquelles pàgines on no s'indiqui la posició del segell`,
        PARRAFO_INDIQUE_PAGINA_POSICION_SELLO: 'Indiqueu la pàgina de referència i posició del segell',
        PAGINAS_EDITADAS: 'Pàgines editades',
        PAGINAS_PENDIENTES_DE_EDITAR: `Pàgines pendents d'editar`,
        PAGINA_REFERENCIA: 'Pàgina de referència',
        ORIENTACION_DEL_SELLO: 'Orientació del segell',
        GIRAR: 'Girar',
        PARRAFO_MANTENDRA_GIRO: 'Es mantindrà el gir del segell a totes les pàgines del document',
        PARRAFO_UBIQUESE_FIRMAR_ESCRIBIR_NAVEGACION: 'Ubiqueu-vos sobre la pàgina on vulgueu signar, podeu escriure directament el número de pàgina o desplaceu-vos a través de la navegació',
        PARRAFO_ARRASTRE_IMAGEN_FIRMAR_DOCUMENTO: 'Arrossegueu la imatge de la signatura i deixeu-la anar on vulgueu signar el document',
        PARRAFO_CLICK_BOTON_FIRMAR: 'Feu clic sobre el botó de "Signar" per signar i finalitzar el procés de signat',
        PARRAFO_ACTUALIZAR_TODAS_PAGINAS: 'Voleu actualitzar totes les pàgines, incloent-hi les pàgines editades?',
        MANTENER_PAGINAS_EDITADAS: 'Mantenir pàgines editades',
        SI_ACTUALIZAR_TODAS_PAGINAS: 'Sí, actualitzar totes les pàgines',
        MARCA_VISADO: 'Marca visat',
        COLEGIADOS_SEGUROS_INACTIVOS: 'Col·legiades assegurances inactives',
        COBROS_AFECTADOS: 'Cobrament(s) afectats',
        NO_HAY_LINEAS_FACTURA: 'No hi ha línies de factura',
        CREAR_COBRO: 'Crear cobrament',
        LISTA_SERVICIOS_LINEA: 'Llista de serveis en línia',
        PARRAFO_SEGURO_ANULAR_INSCRIPCION: 'Esteu segur que voleu anul·lar la inscripció?',
        PARRAFO_SEGURO_ELIMINAR_EVENTO: `Esteu segur que voleu eliminar l'esdeveniment?`,
        SI_ELIMINAR: 'Si, eliminar',
        ANADIR_COLEGIO: 'Afegir col·legi',
        EDITAR_COLEGIO: 'Editar col·legi',
        EDITAR_VARIABLES: 'Editar variables',
        CODIGO_ESPECIAL: 'Codi especial',
        PARRAFO_SEGURO_CAMBIAR_ESTADO_EVENTO_A: `Esteu segur de canviar l'estat de l'esdeveniment a`,
        PARRAFO_SEGURO_ELIMINAR_COBRO: 'Esteu segur que voleu eliminar el cobrament?',
        PARRAFO_SEGURO_ELIMINAR_PROFORMA: 'Esteu segur que voleu eliminar la proforma?',
        PARRAFO_SEGURO_SUPRIMIR_LINEAS_FACTURA: 'Esteu segur que voleu suprimir les línies de factura seleccionades?',
        PARRAFO_SEGURO_ELIMINAR_DERECHO: 'Esteu segur que voleu eliminar el dret?',
        PARRAFO_SEGURO_ELIMINAR_ELEMENTO: `Esteu segur que voleu suprimir l'element?`,
        PARRAFO_SEGURO_CAMBIAR_ESTADO_A: `Esteu segur que voleu canviar l'estat a`,
        FACTURA_RECTIFICADA: 'Factura rectificada',
        FACTURA_RECTIFICATIVA: 'Factura rectificativa',
        NUEVO_BANCO: 'Nou Banc',
        EDITAR_BANCO: 'Editar Banc',
        NUEVA_CONDICION_DE_PAGO: 'Nova Condició pagament',
        EDITAR_CONDICION_PAGO: 'Editar Condició de pagament',
        NUEVO_SERVICIO: 'Nou Servei',
        EDITAR_SERVICIO: 'Editar Servei',
        NO_HAY_ARCHIVOS_ADJUNTOS: 'No hi ha fitxers adjunts',
        NO_HAY_PATROCINADORES: 'No hi ha patrocinadors',
        PARRAFO_SELECCIONE_ESTADO_CAMBIAR: 'Seleccioneu un estat a canviar',
        PARRAFO_SELECCIONE_CONDICION_PAGO_CAMBIAR: 'Seleccioneu la condició de pagament a canviar',
        SI_CAMBIAR: 'Sí, canviar',
        LISTA_LINEAS_FACTURA: 'Llista de Línies de Factures',
        LISTA_REMESAS: 'Llista de Remeses',
        EXCEL_VISADOS: 'Excel visats',
        EXCEL_VISADOS_TRABAJOS: 'Excel visats treballs',
        NUM_POLIZA: 'Núm. pòlissa',
        DAR_DE_ALTA: `Donar d'alta`,
        CREAR_TICKET: `Crear tiquet`,
        URGENCIA: `Urgència`,
        PRODUCTO: `Producte`,
        PERSONA_REGISTRO: `Persona registre`,
        TECNICO_ASOCIADO: `Tècnic associat`,
        FECHA_INICIO_INTERVENCION: `Data inici intervenció`,
        FECHA_FIN_INTERVENCION_DESDE: `Data fi intervenció des`,
        FECHA_FIN_INTERVENCION_HASTA: `Data fi intervenció fins`,
        AREA_TICKETING: `Àrea Tiqueting`,
        TICKETS: `Tiquets`,
        FECHA_MODIFICACION: `Data modificació`,
        TIEMPO: `Temps`,
        NUEVO_TICKET: `Nou tiquet`,
        ASIGNAR_TECNICO: `Assignar tècnic`,
        MUY_URGENTE: `Molt urgent`,
        OBSERVACIONES_TECNICO: `Observacions tècnic`,
        ENVIAR_MENSAJE: `Enviar missatge`,
        PRODUCTOS: `Productes`,
        EDITAR_PRODUCTO: `Editar producte`,
        NUEVO_PRODUCTO: `Nou producte`,
        AGREGAR_ENTIDAD: `Afegir entitat`,
        ESTADOS: `Estats`,
        NUEVO_ESTADO: `Nuevo estado`,
        EDITAR_ESTADO: `Editar estado`,
        ESTADOS_TICKET: `Estats Ticket`,
        NUEVA_URGENCIA: `Nova urgència`,
        EDITAR_URGENCIA: `Editar urgència`,
        URGENCIAS_TICKET: `Urgències Ticket`,
        URGENCIAS: `Urgències`,
        TICKET: `Tiquet`,
        EDITAR_TICKET: `Editar tiquet`,
        ENVIAR_SIN_COMUNICAR: `Enviar sense comunicar`,
        GESTOR_TIPO_DE_MODULOS: `Gestor Tipus de mòduls`,
        LISTA_TIPOS_DE_MODULOS: `Llista tipus de mòduls`,
        TIPO_DE_MODULO: `Tipus de mòdul`,
        EDITAR_TIPO_DE_MODULO: `Edita Tipus de mòdul`,
        NUEVO_TIPO_DE_MODULO: `Nou Tipus de mòdul`,
        CERRAR_TICKET: `Tancar Tiquet`,
        REABRIR_TICKET: `Reobrir Ticket`,
        TECNICO: `Tècnic`,
        ENVIAR_CERRAR_TICKET: `Enviar i tancar tiquet`,
        ALERT_OPCION_DISPONIBLE_CREAR_ENTIDAD: `Les persones i els productes es gestionaran després de crear l'entitat`,
        AGREGAR_PRODUCTO: 'Afegeix producte',
        PLANT_ALIAS_CLIENTES: `Plant. àlies clients`,
        PLANT_ALIAS_TECNICOS: `Plant. àlies tècnics`,
        FECHA_ALTA: `Data d'alta`,
        BUZON_EMAILS: `Bústia emails`,
        FECHA_RECIBIDO: `Data rebut`,
        REMITENTE: `Remitent`,
        DESTINATARIO: `Destinatari`,
        SOLO_PENDIENTES: `Només pendents`,
        SINCRONIZANDO_BUZON_EMAILS: `Sincronitzant Buzon Emails`,
        SINCRONIZANDO: `Sincronitzant`,
        DESTINATARIOS: `Destinataris`,
        ELIMINAR_TICKET: 'Eliminar tiquet',
        PARRAFO_SEGURO_ELIMINAR_TICKET: 'Esteu segur de voler eliminar el tiquet?',
        SINCRONIZAR: 'Sincronitzar',
        RELACIONES_PERSONA_ENTIDAD: `Relacions Persona-Entitat`,
        ADMINISTRADOR: `Administrador`,
        VER_MENOS: `Veure menys`,
        ENVIADO_POR: `Enviat per`,
        TICKETS_ACTIVOS: `Tiquets actius`,
        SOLO_ADMIN: `Només Admin`,
        OCULTO: `Ocult`,
        EDITAR_MENSAJE: `Editar missatge`,
        GENERAR_CERTIFICADO: `Generar certificat`,
        NO_PUBLICAR: `No publicar`,
        NO_COMUNICAR: `No comunicar`,
        GENERANDO_FACTURA: `Generant factura`,
        P_GUARDAR_NO_GENERAR: `El desament de les dades no afectarà la factura generada`,
        P_MENSAJE_PUBLICO: `Aquest camp serà visible als usuaris a la part pública`,


    }
}

export default lang;