import langTheme from "../assetsTheme/language/ES"

const lang = {
    ES: {
        ...langTheme['ES'],
        REGRESAR_AL_PORTAL: "Regresar al portal",
        ESPANIOL: "Español",
        CATALAN: "Catalán",
        CASTELLANO: 'Castellano',
        ADMINISTRACION: 'Administración',
        AREA_DE_FACTURACION: 'Área de Facturación',
        AREA_DE_ADMINISTRACION: 'Área de Administración',
        AREA_DE_VISADOS: `Área de Visados`,

        FACTURACION: 'Facturación',
        GESTOR_DE_CONTENIDOS: 'Gestor de contenidos',
        MIS_DATOS: 'Mis datos',
        INTRANET_DE_ENGINYERS: 'Intranet de Ingenieros',
        PERSONAS: 'Personas',
        PERSONA: 'Persona',
        PERSONA_NUEVA: 'Persona nueva',
        PARAMETROS: 'Parámetros',
        USUARIOS: 'Usuarios',
        INICIO: 'Inicio',
        PLANTILLAS: 'Plantillas',
        LISTA_DE_PERSONAS: 'Lista de personas',
        DATOS_PERSONALES: 'Datos personales',
        DATOS_GENERALES: 'Datos generales',
        DATOS_COLEGIALES: 'Datos colegiales',
        DATOS_PROFESIONALES: 'Datos profesionales',
        SEGUROS: 'Seguros',
        DATOS_ECONOMICOS: 'Datos económicos',
        COMUNICACIONES: 'Comunicaciones',
        HISTORICOS: 'Históricos',
        ENTIDADES: 'Entidades',
        LISTA_DE_ENTIDADES: 'Lista de entidades',
        CREAR_ENTIDAD: 'Crear Entidad',
        SEXO: 'Sexo',
        FECHA_NACIMIENTO: 'Fecha nacimiento',
        PAIS_NACIONALIDAD: 'País nacionalidad',
        ESTADO_CIVIL: 'Estado civil',
        DIRECCION_FISCAL: 'Dirección fiscal',
        CODIGO_POSTAL: 'Código postal',
        PAIS: 'País',
        MUNICIPIO: 'Municipio',
        PROVINCIA: 'Província',
        TELEFONO_1: 'Teléfono 1',
        TELEFONO_2: 'Teléfono 2',
        EXTENSION_1: 'Extensión 1',
        EXTENSION_2: 'Extensión 2',
        TELEFONO_MOVIL: 'Teléfono móvil',
        FAX: 'FAX',
        EMAIL: 'E-mail',
        OBSERVACIONES: 'Observaciones',
        DATOS_DE_CONTACTO: 'Datos de contacto',
        LISTA_DE_USUARIOS: 'Lista de usuarios',
        MENSAJE: 'Mensaje',
        CODIGOS_ADICIONALES: 'Códigos adicionales',
        DOCUMENTACION: 'Documentación',
        TIPO: 'Tipo',
        SEDE: 'Sede',
        FECHA_DE_ALTA: 'Fecha de alta',
        FECHA_DE_BAJA: 'Fecha de baja',
        FECHA_INICIO: 'Fecha inicio',
        FECHA_FIN: 'Fecha fin',
        NUM_COLEGIADO: 'Núm. Colegiado',
        FECHA_SOLICITUD: 'Fecha solicitud',
        SERVICIOS: 'Servicios',
        CONDICIONES_DE_PAGO: 'Condiciones de pago',
        CONDICION_DE_PAGO: 'Condición de pago',
        BANCOS: 'Bancos',
        LISTA_DE_BANCOS: 'Lista de bancos',
        LISTA_DE_CONDICIONES_PAGO: 'Lista de Condiciones de pago',
        LISTA_DE_SERVICIOS: 'Lista de Servicios',
        LIMPIAR: 'Limpiar',
        BUSCAR: 'Buscar',
        NUEVA_GALERIA: 'Nueva galería',
        GALERIA: 'Galería',
        GALERIAS: 'Galerías',
        SERVICIO: 'Servicio',
        VOLVER: 'Volver',
        NUEVO_SERVICIO_LINEA: 'Nuevo servicio en linea',
        SERVICIO_LINEA: 'Servicio en linea',
        HOMBRE: 'Hombre',
        MUJER: 'Mujer',
        NUEVO_USUARIO: 'Nuevo usuario',
        LISTA_DE_PERFILES: 'Lista de perfiles',
        NUEVO_PERFIL: 'Nuevo perfil',
        EDITAR_PERFIL: 'Editar perfil',
        PERFIL: 'Perfil',
        ALIAS: 'Alias',
        LISTA_DE_MODULOS: 'Lista de módulos',
        NUEVO_MODULO: 'Nuevo módulo',
        EDITAR_MODULO: 'Editar Módulo',
        MODULO: 'Módulo',
        PLANTILLA_NUEVA: 'Plantilla nueva',
        GUARDAR: 'Guardar',
        NUEVO_CODIGO: 'Nuevo código',
        EDITAR_CODIGOS: 'Editar códigos',
        INSCRIPCIONES: 'Inscripciones',
        MIS_INSCRIPCIONES: 'Mis inscripciones',
        ALQUILER_DE_APARATOS: 'Alquiler de Aparatos',
        VISADOS: 'Visados',
        VISADOR: 'Visador',
        GUIA_DE_PROFESIONALES: 'Guía de Profesionales',
        LISTA_DE_NOTICIAS: 'Lista de Noticias',
        NUEVA_NOTICIA: 'Nueva Noticia',
        PUBLICAR: 'Publicar',
        DESPUBLICAR: 'Despublicar',
        LISTA_DE_PATROCINADORES: 'Lista de Patrocinadores',
        NUEVO_PATROCINADOR: 'Nuevo Patrocinador',
        ELIMINAR: 'Eliminar',
        ALERT_URL_OBLIGATORIO: 'La URL es obligatoria.',
        ALERT_NOMBRE_OBLIGATORIO: 'El nombre es obligatorio.',
        SALIR: 'Salir',
        CANCELAR: 'Cancelar',
        LINEAS: 'Líneas',
        FACTURAS: 'Facturas',
        COBROS: 'Cobros',
        LINEAS_FACTURA_PENDIENTES: 'Líneas de Factura pendientes',
        CUOTAS: 'Cuotas',
        CUOTA: 'Cuota',
        LISTA_DE_REMESAS: 'Lista de Remesas',
        LISTA_DE_COBROS: 'Lista de Cobros',
        NUEVO_COBRO: 'Nuevo Cobro',
        EDITAR_COBRO: 'Editar Cobro',
        NUEVA_PERSONA: 'Nueva Persona',
        EXPORTAR_EXCEL: 'Exportar Excel',
        RELACION: 'Relación',
        PERMISO_EDICION: 'Permiso edición',
        MOSTRAR_EN_REGISTRO_COLEGIADOS: 'Mostrar en el registro de colegiados',
        CARGO: 'Cargo',
        ENTIDAD: 'Entidad',
        NUEVA_ENTIDAD: 'Nueva Entidad',
        EDITAR_ENTIDAD: 'Editar Entidad',
        PORCENTAJE_PARTICIPACION: 'Porcentaje participación',
        DAR_DE_BAJA: 'Dar de baja',
        BAJA: 'Baja',

        PARRAFO_1_COLEGIADO_EMITIR_VISADOS: 'El colegiado podrá emitir visados a través de esta entidad',
        NUEVA_RELACION: 'Nueva Relación',
        EDITAR_RELACION: 'Editar Relación',
        LISTA_DE_RELACIONES: 'Lista de Relaciones',
        LISTA_DE_TIPOSNOTICIA: 'Lista de Tipos Noticia',
        LISTA_DE_TIPOSCONTENIDO: 'Lista de Tipos Contenido',
        NUEVO_TIPONOTICIA: 'Nuevo Tipo Noticia',
        NUEVO_TIPOCONTENIDO: 'Nuevo Tipo Contenido',
        EDITAR_TIPONOTICIA: 'Editar Tipo Noticia',
        EDITAR_TIPOCONTENIDO: 'Editar Tipo Contenido',
        TIPOS_NOTICIA: 'Tipos Noticia',
        TIPO_NOTICIA: 'Tipos Noticia',
        TIPO_CONTENIDO: 'Tipo Contenido',
        ARCHIVOS_ADJUNTOS: 'Archivos adjuntos',
        NUEVO_FICHERO: 'Nuevo Fichero',
        CUENTAS_BANCARIAS: 'Cuentas Bancarias',
        CUENTA_BANCARIA: 'Cuenta Bancaria',
        NUEVA_CUENTA_BANCARIA: 'Nueva Cuenta bancaria',
        EDITAR_CUENTA_BANCARIA: 'Editar Cuenta bancaria',
        TITULAR_DE_CUENTA: 'Titular de la cuenta',
        SELECCIONE_UNA_ENTIDAD: 'Seleccione una Entidad',
        SELECCIONE_UN_BANCO: 'Seleccione un Banco',
        SELECCIONE_UNA_FACTURA: 'Seleccione una Factura',
        SELECCIONE_UNA_PERSONA: 'Seleccione una Persona',
        SELECCIONE_UNA_REMESA: 'Seleccione una Remesa',
        ESTADO: 'Estado',
        FECHA_ESTADO: 'Fecha estado',
        NOMBRE: 'Nombre',
        FACTURA: 'Factura',
        LINEAS_DE_FACTURA: 'Líneas de factura',
        ABONAR: 'Abonar',
        NUEVA_LINEA_FACTURA: 'Nueva Línea factura',
        EDITAR_LINEA_FACTURA: 'Editar Línea factura',
        ACTIVIDADES_PROFESIONALES: 'Actividades Profesionales',
        ACTIVIDAD_PROFESIONAL: 'Actividad Profesional',
        NUEVA_ACTIVIDAD_PROFESIONAL: 'Nueva Actividad Profesional',
        EDITAR_ACTIVIDAD_PROFESIONAL: 'Editar Actividad Profesional',

        INHABILITACIONES: 'Inhabilitaciones',
        INHABILITACION: 'Inhabilitación',
        NUEVA_INHABILITACION: 'Nueva Inhabilitación',
        EDITAR_INHABILITACION: 'Editar Inhabilitación',

        MENU_PRINCIPAL: 'Menú principal',
        DESCARGAR_PDFS_AGRUPADOS: 'Descargar PDFs Agrupados',
        DESCARGAR_PDFS: 'Descargar PDF(s)',
        DESCARGAR_PDF: 'Descargar PDF',
        DESCARGANDO: 'Descargando',
        ENVIAR_EMAILS: 'Enviar EMAIL(s)',
        ENVIAR_EMAIL: 'Enviar EMAIL',
        NOTIFICANDO: 'Notificando',
        REMESAS: 'Remesas',
        PARRAFO_SELECCIONAR_ALMENOS_1: 'Es necesario seleccionar al menos 1 resultado de busqueda',
        PARRAFO_SELECCIONE_ALMENOS_1: 'Seleccione almenos 1 resultado de busqueda',
        NUEVO_SEGURO: 'Nuevo seguro',
        EDITAR_SEGURO: 'Editar seguro',
        
        PARRAFO_OPERACION_EXITO: 'La operación se ha realizado con éxito',

        ALERT_OBLIGATORIO_CUENTA_PROPIA: 'La cuenta propia es obligatoria.',
        ALERT_OBLIGATORIO_CUENTA_BANCARIA: 'La cuenta bancaria es obligatoria.',
        ALERT_OBLIGATORIO_CONDICION_PAGO: 'La Condición de pago es obligatoria.',
        ALERT_OBLIGATORIO_FECHA_REGISTRO: 'La Fecha registro es obligatoria.',
        ALERT_OBLIGATORIO_ESTADO: 'El Estado es obligatorio.',
        ALERT_OBLIGATORIO_FACTURA: 'La Factura es obligatoria.',

        DATOS_REQUERIDOS: 'Datos requeridos',
        GENERAR_FICHERO: 'Generar Fichero',

        PARRAFO_USARAN_ELEMENTOS_SELECCIONADOS: 'Se usarán los elementos seleccionados de la búsqueda actual',
        PARRAFO_USARAN_ELEMENTOS_BUSQUEDA: 'Se usarán los elementos de la búsqueda actual',
        PARRAFO_GENERARA_FICHERO_REMESA: 'Se generá el fichero para la remesa',

        PARRAFO_USAR_BUSQUEDA_ACTUAL: 'Usar la búsqueda actual',
        PARRAFO_BUSCAR_REMESA_EXISTENTE: 'Buscar una remesa existente',

        DESCARGAR_EXCEL: 'Descargar Excel',
        PARRAFO_SIN_MEDIOS_PAGO: 'El servicio seleccionado no tiene Medios de pago',

        MEDIOS_DE_PAGO: 'Medios de pago',
        CUENTA_PROPIA: 'Cuenta propia',
        CUENTAS_PROPIAS: 'Cuentas propias',
        NUM_COBROS: 'Num. Cobros',

        ALERT_OBLIGATORIO_NIF: 'El NIF es obligatorio.',
        ALERT_OBLIGATORIO_GENERICO: 'El Campo es obligatorio.',
        ALERT_OPCION_DISPONIBLE_LUEGO_CREAR: 'Esta opción esta disponible luego de crear a la persona',
        ALERT_OPCION_DISPONIBLE_LUEGO_CREAR_NOTICIA: 'Esta opción esta disponible luego de crear la noticia',
        ALERT_OPCION_DISPONIBLE_LUEGO_CREAR_APARATO: 'Esta opción esta disponible luego de crear el aparato',
        ALERT_OPCION_DISPONIBLE_LUEGO_CREAR_OFERTA: `Esta opción esta disponible luego de crear la oferta`,


        DADO_DE_BAJA: 'Dado de baja',
        JUBILADO: 'JUBILADO',
        GRUPOS: 'Grupos',
        NUEVO_GRUPO: 'Nuevo Grupo',
        EDITAR_GRUPO: 'Editar Grupo',
        SEDES: 'Sedes',
        SEDE_CENTRAL: 'Sede central',
        NUEVA_SEDE: 'Nueva Sede',
        EDITAR_SEDE: 'Editar Sede',

        FECHA_BAJA: 'Fecha baja',
        FECHA_DE_REGISTRO: 'Fecha de registro',
        FECHA_VENCIMIENTO: 'Fecha de vencimiento',
        FECHA_COBRO: 'Fecha de cobro',
        FECHA_PAGO: 'Fecha de pago',
        NUEVA_CUOTA: 'Nueva Cuota',

        PORTADA: 'Portada',
        NOTICIAS: 'Noticias',
        PATROCINADORES: 'Patrocinadores',
        MULTIMEDIA: 'Multimedia',

        AGREGAR: 'Agregar',
        PAGADORES: 'Pagadores',
        
        SELECCIONAR_ASOCIADOS: 'Seleccionar asociados',
        DATOS_ADICIONALES: 'Datos adicionales',
        IR_A_LINEAS_FACTURA: 'Ir a Lineas de factura',
        IR_A_FACTURAS: 'Ir a Facturas',

        CREAR: 'Crear',
        NUEVA_FACTURA: 'Nueva Factura',
        NUEVA_PROFORMA: 'Nueva Proforma',

        VER_FACTURA: 'Ver Factura',
        PARRAFO_FACTURA_HA_SIDO_PAGADA: '¿La factura ha sido pagada?',

        FINALIZAR: 'Finalizar',
        NUEVA: 'Nueva',
        EN_PROCESO: 'En proceso',
        HISTORICO: 'Histórico',
        NUEVA_TRAMITACION: 'Nueva tramitación',

        LISTA_DE_VISADOS: 'Lista de Visados',
        LISTA_DE_VISADOS_RVD: 'Lista de Visados / RVD',
        VISADO: 'Visado',
        FECHA_ENTRADA: 'Fecha entrada',

        FORMACION: 'Formación',

        ESTUDIO: 'Estudio',
        ESPECIALIDAD: 'Especialidad',
        CENTRO: 'Centro',

        ANADIR_ESTUDIO: 'Añadir estudio',
        EDITAR_ESTUDIO: 'Editar estudio',
        TIPO_ESTUDIO: 'Tipo estudio',

        ANIO_FIN: 'Año fin',
        FOLIO: 'Folio',
        VOLUMEN: 'Volumen',
        TIPO_DE_ENTIDAD: 'Tipo de entidad',
        FECHA_JUBILACION: 'Fecha jubilación',

        JUBILACION: 'Jubilación',
        REACTIVAR: 'Reactivar',
        GRUPO: 'Grupo',

        TITULO: 'Título',
        SELECCIONAR_USUARIO: 'Seleccionar usuario',
        ASIGNAR_PERFIL: 'Asignar perfil',
        SELECCIONE_CERTIFICADO: 'Seleccione certificado',
        FIRMA: 'Firma',
        CONTINUAR: 'Continuar',
        CREDENCIALES: 'Credenciales',

        IR_AREA_CLIENTE: 'Ir a Área cliente',
        IR_AREA_ADMINISTRACION: 'Ir a Área Administración',
        CAMBIAR_CONTRASENIA: 'Cambiar contraseña',
        CAMBIAR: 'Cambiar',

        CONTRASENIA: 'Contraseña',
        REPETIR_CONTRASENIA: 'Repetir contraseña',

        EN_REVISION: 'En revisión',
        APROBADOS: 'Aprobados',

        INCIDENCIAS: 'Incidencias',
        ANULADOS: 'Anulados',

        CONTRASENIA_ACTUAL: 'Contraseña actual',
        NUEVA_CONTRASENIA: 'Nueva contraseña',
        CONFIRMAR_NUEVA_CONTRASENIA: 'Confirmar nueva contraseña',
        TERCEROS: 'Terceros',
        DOCUMENTOS: 'Documentos',
        DOCUMENTO: 'Documento',
        REVISION_TECNICA: 'Revisión técnica',
        REVISION_ECONOMICA: 'Revisión económica',
        TIPO_DOCUMENTO: 'Tipo documento',
        NUEVO_DOCUMENTO: 'Nuevo documento',
        EDITAR_DOCUMENTO: 'Editar documento',
        FICHERO_ADJUNTO: 'Fichero Adjunto',
        ADJUNTO: 'Adjunto',
        GUARDAR_POSICION: 'Guardar posición',
        FIRMAR: 'Firmar',
        CLIENTE: 'Cliente',
        CLIENTES: 'Clientes',
        NUEVO_CLIENTE: 'Nuevo cliente',
        EDITAR_CLIENTE: 'Editar cliente',
        COLEGIADOS: 'Colegiados',
        EMPLAZAMIENTO: 'Emplazamiento',
        DIRECCION: 'Dirección',
        IMPORTE: 'Importe',
        NUEVO_TIPO_DOC_TIPO_TRAB: 'Nuevo tipo documento / tipo trabajo',
        NUEVO: 'Nuevo',
        EDITAR: 'Editar',
        ALERT_ENTIDAD_OBLIGATORIA: 'La entidad es obligatoria.',
        FECHA_DE_PUBLICACION: 'Fecha de publicación',
        PUBLICADO: 'Publicado',
        PUBLICADOS: 'Publicados',
        DESCRIPCION: 'Descripción',
        DESCRIPCION_CORTA: 'Descripción corta',
        IMAGEN: 'Imagen',
        FECHA_REGISTRO: 'Fecha registro',
        PUBLICAR_DESDE: 'Publicar desde',
        PUBLICAR_HASTA: 'Publicar hasta',
        NOTICIA_EXTERNA: 'Noticia externa',
        DESCRIPCION_LARGA: 'Descripción larga',
        ENLACE_EXTERNO_NOTICIA: 'Enlace externo de la noticia',
        NOMBRE_COMERCIAL: 'Nombre comercial',
        ENLACE: 'Enlace',
        FECHA_DESDE: 'Fecha desde',
        MANTENER_ORIGINAL: 'Mantener original',
        RECORTAR_IMAGEN: 'Recortar imagen',
        TIPOS_DOCUMENTO: 'Tipos documento',
        TIPOS_TRABAJO: 'Tipos trabajo',

        CADENA: 'Cadena',
        NUEVO_TIPO_DOCUMENTO: 'Nuevo tipo documento',
        EDITAR_TIPO_DOCUMENTO: 'Editar tipo documento',
        COD_TIPO_DOCUMENTO: 'Cod. Tipo documento',
        ESTADISTICA: 'Estadística',

        NUEVO_TIPO_TRABAJO: 'Nuevo tipo trabajo',
        EDITAR_TIPO_TRABAJO: 'Editar tipo trabajo',
        COD_TIPO_TRABAJO: 'Cod. Tipo trabajo',
        SUBTITULO: 'Subtítulo',
        PARRAFO_NO_HAY_DATOS_PARA_MOSTRAR: 'No hay datos para mostrar',
        NUEVO_GRUPO_MULTIMEDIA: 'Nuevo grupo multimedia',
        EDITAR_GRUPO_MULTIMEDIA: 'Editar grupo multimedia',
        NUEVO_VIDEO: 'Nuevo video',
        EDITAR_VIDEO: 'Editar video',
        PAGINAS: 'Páginas',
        NUEVA_PAGINA: 'Nueva página',
        AGREGAR_ITEM: 'Agregar ítem',
        AGREGAR_GALERIA: 'Agregar galería',
        AGREGAR_NOTICIA: 'Agregar notícia',
        AGREGAR_NOTICIAS: 'Agregar noticias',
        SELECCIONE_UNA_GALERIA: 'Seleccione una galería',
        SELECCIONE_UN_ITEM: 'Seleccione un item',
        SELECCIONE_UNA_NOTICIA: 'Seleccione una notícia',
        SELECCIONE_UN_PATROCINADOR: 'Seleccione un patrocinador',
        ACCIONES: 'Acciones',
        PUBLICAR_EN_WEB: 'Publicar en web',
        GUARDAR_CAMBIOS: 'Guardar cambios',
        DERECHOS: 'Derechos',
        LISTA_DE_DERECHOS: 'Lista de derechos',
        SERVICIO_RVD: 'Servicio RVD',
        SERVICIO_VISADO: 'Servicio visado',
        FIRMADO: 'Firmado',
        SELLO: 'Sello',
        SELECCIONE_UN_VISADO: 'Seleccione un Visado',
        BORRADORES: 'Borradores',
        NUEVO_DERECHO: 'Nuevo derecho',
        TIPO_TRABAJO: 'Tipo trabajo',
        AUTOMATICO: 'Automático',
        COMPROBANDO_VISADO: 'Comprobando visado',
        SOLICITAR_REVISION: 'Solicitar revisión',
        PARRAFO_SOLICITAR_REVISION: 'Al solicitar la revisión no podrá modificar el visado',
        DESCARGAR: 'Descargar',
        PARRAFO_INFORMACION_REVISION_FICHA_VISADO: 'El visado esta en revisión, se le notificará cuando los técnicos hayan revisado su visado',
        PARRAFO_INFORMACION_APROBADO_FICHA_VISADO: 'El visado esta aprobado, se le notificará cuando los técnicos hayan revisado su visado',
        COMPROBAR_Y_SOLICITAR: 'Comprobar y solicitar',
        ELIMINAR_CONTROL: 'Eliminar control',
        REQUISITO: 'Requisito',
        NO: 'No',
        SI: 'Si',
        NO_APLICA: 'No aplica',
        CONTROL: 'Control',
        SELECCIONE_CONTROL: 'Seleccione control',
        AGREGAR_CONTROL: 'Agregar control',
        NUEVA_INCIDENCIA: 'Nueva incidencia',
        EDITAR_INCIDENCIA: 'Editar incidencia',
        INCIDENCIA: 'Incidencia',
        DESCARTADA: 'Descartada',
        GESTIONAR_INCIDENCIAS: 'Gestionar incidencias',
        FIRMA_PENDIENTE: 'Firma pendiente',
        APROBAR: 'Aprobar',
        ANULAR: 'Anular',
        CAMBIAR_ESTADO: 'Cambiar estado',
        SI_ANULAR: 'Si, anular',
        SI_APROBAR: 'Si, aprobar',
        SI_CAMBIAR_INCIDENCIA: 'Si, cambiar a incidencia',
        PARRAFO_CONFIRMAR_ANULAR_VISADO: '¿Está seguro que desea anular el visado?',
        PARRAFO_CONFIRMAR_APROBAR_VISADO: '¿Está seguro que desea aprobar el visado?',
        PARRAFO_CONFIRMAR_INCIDENCIA_VISADO: '¿Está seguro que desea cambiar a estado incidencia el visado?',
        FECHA_SALIDA: 'Fecha salida',
        PARRAFO_BUSQUEDA_ACTUAL: 'Se usará la busqueda actual',
        FECHA_SALIDA_DESDE: 'Fecha salida desde',
        FECHA_SALIDA_HASTA: 'Fecha salida hasta',
        NUM_ASOCIADO: 'Num. asociado',
        CONVENIO: 'Convenio',
        COLEGIADO: 'Colegiado',
        ELIMINAR_BORRADOR: 'Eliminar borrador',
        LISTA_DE_FACTURAS: 'Lista de facturas',
        LISTA_DE_PLANTILLAS: 'Lista de plantillas',
        TIPO_PLANTILLA: 'Tipo plantilla',
        ENVIAR: 'Enviar',
        COMUNICAR_INCIDENCIAS: 'Comunicar incidencias',
        FECHA_ADJUNTO: 'Fecha adjunto',
        TIPO_ADJUNTO: 'Tipo adjunto',
        MOSTRAR_A_INTERESADO: 'Mostrar a interesado',
        NUEVA_DOCUMENTACION: 'Nueva documentación',
        EDITAR_DOCUMENTACION: 'Editar documentación',
        FORMACION_EVENTOS: 'Formación / Eventos',
        ENTIDAD_PAGADORA: 'Entidad pagadora',
        ENVIAR_BOLETIN: 'Enviar boletín',
        ASUNTO: 'Asunto',
        FECHA_CREACION: 'Fecha creación',
        LISTA_DE_COMUNICACIONES: 'Lista de Comunicaciones',
        BOLETIN: 'Boletín',
        REMITENTE_NOMBRE: 'Remitente nombre',
        REMITENTE_EMAIL: 'Remitente email',
        FECHA_ENVIADO: 'Fecha enviado',
        LISTA_DE_DESTINATARIOS: 'Lista de Destinatarios',
        PLANTILLA: 'Plantilla',
        EVENTO: 'Evento',
        LISTA_DE_EVENTOS: 'Lista de eventos',
        VER_VISADO_RELACIONADO: 'Ver visado relacionado',
        TRAMITACION: 'Tramitación',
        NUMERO_VISADO: 'Num. visado',
        FECHA_VISADO: 'Fecha visado',
        NUMERO_ENTRADA: 'Num. entrada',
        TIPOS_CLASIFICACION: 'Tipos Clasificación',
        CODIGO: 'Código',
        EDITAR_TIPOCLASIFICACION: 'Editar Tipo Clasificación',
        NUEVO_TIPOCLASIFICACION: 'Nuevo Tipo Clasificación',
        LISTA_DE_TIPOSNOTICIACLASIFICACION: 'Lista de Tipos de Noticias Clasificación',
        CLASIFICACION: 'Clasificación',
        PUBLICAR_EN: 'Publicar en',
        FECHA_HASTA: 'Fecha hasta',
        TIPOS_DE_ESPECIALIDADES: 'Tipos de especialidades',
        NUEVA_ESPECIALIDAD: 'Nueva especialidad',
        EDITAR_ESPECIALIDAD: 'Editar especialidad',
        EVENTOS: 'Eventos',
        TIPO_EVENTO: 'Tipo evento',
        DESCRIPCION_IDIOMA: 'Descripción idioma',
        FECHAS: 'Fechas',
        TARIFAS: 'Tarifas',
        AREA_DE_INSCRIPCIONES: 'Área de inscripciones',
        NUEVO_EVENTO: 'Nuevo evento',
        FECHA_INICIO_INSCRIPCION: 'Fecha inicio inscripción',
        FECHA_FIN_INSCRIPCION: 'Fecha fin inscripción',
        AFORO: 'Aforo',
        GRATUITO: 'Gratuito',
        CREDITOS: 'Créditos',
        NOMBRE_DEL_EVENTO: 'Nombre del evento',
        INFORMACION_ADICIONAL: 'Información adicional',
        DIRIGIDO_A: 'Dirigido a',
        LUGAR: 'Lugar',
        OBJETIVO: 'Objetivo',
        PONENTES: 'Ponentes',
        FECHA_EVENTO: 'Fecha evento',
        HORA_INICIO: 'Hora inicio',
        HORA_FIN: 'Hora fin',
        HORARIO_DEL_EVENTO: 'Horario del evento',
        HORARIOS: 'Horarios',
        NOMBRE_DOCUMENTO: 'Nombre documento',
        NUEVA_TARIFA: 'Nueva tarifa',
        EDITAR_TARIFA: 'Editar tarifa',
        TARIFA_APLICADA_A: 'Tarifa aplicada a',
        PRECIO: 'Precio',
        TARIFA: 'Tarifa',
        FECHA_FACTURA: 'Fecha Factura',
        IMAGEN_PORTADA: 'Imagen portada',
        ALERT_OPCION_DISPONIBLE_LUEGO_CREAR_EVENTO: 'Esta opción está disponible luego de crear el evento',
        VER_CALENDARIO: 'Ver calendario',
        EDITAR_TIPO_ENTIDAD: 'Editar tipo entidad',
        NUEVO_TIPO_ENTIDAD: 'Nuevo tipo entidad',
        FECHA: 'Fecha',
        NUEVA_INSCRIPCION: 'Nueva inscripción',
        EDITAR_INSCRIPCION: 'Editar inscripción',
        DATOS_DE_LA_PERSONA: 'Datos de la persona',
        OTROS_DATOS: 'Otros datos',
        VER_FICHA_PERSONA: 'Ver ficha persona',
        VER_MAS: 'Ver más',
        REENVIAR_PENDIENTE: 'Reenviar pendiente',
        DESTINATARIO_EMAIL: 'Destinatario email',
        CONTENIDOS: 'Contenidos',
        LISTA_DE_CONTENIDOS: 'Lista de contenidos',
        NUEVO_CONTENIDO: 'Nuevo contenido',
        CONTENIDO_EXTERNO: 'Contenido externo',
        DIAS_FESTIVOS: 'Días festivos',
        DIA_FESTIVO: 'Día festivo',
        APARATOS: 'Aparatos',
        NUEVO_APARATO: 'Nuevo aparato',
        MODELO: 'Modelo',
        ALQUILERES: 'Alquileres',
        APARATO: 'Aparato',
        NUEVO_ALQUILER: 'Nuevo alquiler',
        ALQUILER: 'Alquiler',
        TEL: 'Tel.',
        NUM: 'Núm.',
        DIAS_ALQUILER: 'Días alquiler',
        CONDICIONES: 'Condiciones',
        BOLSA_DE_TRABAJO: 'Bolsa de trabajo',
        NUEVO_OFERTA: 'Nueva oferta',
        AREA_BOLSA_TRABAJO: 'Área Bolsa de trabajo',
        OFERTAS: 'Ofertas',
        FECHA_VISADO_DESDE: 'Fecha visado desde',
        FECHA_VISADO_HASTA: 'Fecha visado hasta',
        ENTIDADES_EMISORAS: 'Entidades emisoras',
        NUEVA_ENTIDAD_EMISORA: 'Nueva entidad emisora',
        EDITAR_ENTIDAD_EMISORA: 'Editar entidad emisora',
        LISTA_DE_ENTIDADES_EMISORAS: 'Lista de entidades emisoras',

        NUEVA_CUENTA_PROPIA: 'Nueva cuenta propia',
        EDITAR_CUENTA_PROPIA: 'Editar cuenta propia',
        EFECTIVO: 'Efectivo',
        MIS_FACTURAS: 'Mis facturas',
        IVAS: 'IVAs',
        NUEVO_IVA: 'Nuevo IVA',
        EDITAR_IVA: 'Editar IVA',
        LISTA_DE_IVAS: 'Lista de IVAs',
        CONTROLES: 'Controles',
        LISTA_CONTROLES: 'Lista de controles',
        CLASE_CONTROL: 'Clase control',
        NUEVO_CONTROL: 'Nuevo control',
        EDITAR_CONTROL: 'Editar control',
        TIPO_CONTROL: 'Tipo control',
        LISTA_TIPOS_CONTROL: 'Lista tipos control',
        LISTA_SECCIONES: 'Lista secciones',
        NUEVA_SECCION: 'Nueva sección',
        TIPOS_CONTROLES: 'Tipos controles',
        NUEVO_TIPO_CONTROL: 'Nuevo tipo control',
        LISTA_CONTROL_REQUISITOS: 'Lista control requisitos',
        NUEVO_CONTROL_REQUISITO: 'Nuevo control requisito',
        EDITAR_CONTROL_REQUISITO: 'Editar control requisito',
        PLANTILLAS_CERTIFICADOS: 'Plantillas certificados',
        NUEVA_PLANTILLA: 'Nueva plantilla',
        NOMBRE_PLANTILLA: 'Nombre plantilla',
        PLANTILLA_CERTIFICADO: 'Plantilla certificado',
        CERTIFICADO: 'Certificado',
        REVERSO_PLANTILLA: 'Reverso plantilla',
        SUBIR: 'Subir',
        DESCARGAR_PLANTILLA: 'Descargar plantilla',
        TIENDA: 'Tienda',
        CUENTA_CONTABLE: 'Cuenta contable',
        CUENTAS_CONTABLES: 'Cuentas contables',
        CUENTA_CONTABLE_SOPORTADO: 'Cuenta contable soportado',
        CUENTA_CONTABLE_REPERCUTIDO: 'Cuenta contable repercutido',
        DATOS_CONTABILIDAD: 'Datos contabilidad',
        NUEVA_CUENTA_CONTABLE: 'Nueva cuenta contable',
        EDITAR_CUENTA_CONTABLE: 'Editar cuenta contable',
        TIPO_CUENTA_CONTABLE: 'Tipo cuenta contable',
        ENTIDAD_PROPIA: 'Entidad propia',
        FACTURAS_PROVEEDOR: 'Facturas proveedor',
        PROVEEDOR: 'Proveedor',
        NUMERO_FACTURA: 'Número factura',
        EXPORTAR_A_CONTABILIDAD: 'Exportar a contabilidad',
        PRECIO_POR_DIA: 'Precio por día',
        DIA: 'Día',
        ESTADO_OFERTA: 'Estado oferta',
        ESTADOS_OFERTA: 'Estados oferta',
        NUEVO_ESTADO_OFERTA: 'Nuevo estado oferta',
        EDITAR_ESTADO_OFERTA: 'Editar estado oferta',
        ESTADOS_PERSONA: 'Estados persona',
        NUEVO_ESTADO_PERSONA: 'Nuevo estado persona',
        EDITAR_ESTADO_PERSONA: 'Editar estado persona',
        FUNCIONES: 'Funciones',
        NUEVA_FUNCION: 'Nueva función',
        EDITAR_FUNCION: 'Editar función',
        NUEVO_HORARIO: 'Nuevo horario',
        EDITAR_HORARIO: 'Editar horario',
        NIVELES_IDIOMA: 'Niveles idioma',
        EDITAR_NIVEL_IDIOMA: 'Editar nivel idioma',
        NUEVO_NIVEL_IDIOMA: 'Nuevo nivel idioma',
        NIVEL_IDIOMA: 'Nivel idioma',
        ORDEN_IDIOMA: 'Orden idioma',
        SALARIOS: 'Salarios',
        NUEVO_SALARIO: 'Nuevo salario',
        EDITAR_SALARIO: 'Editar salario',
        TEXTO: 'Texto',
        CANTIDAD: 'Cantidad',
        TIPOS_CONTRATO: 'Tipos contrato',
        NUEVO_TIPO_CONTRATO: 'Nuevo tipo contrato',
        EDITAR_TIPO_CONTRATO: 'Editar tipo contrato',
        OFERTA: 'Oferta',
        HORARIO: 'Horario',
        CATEGORIA: 'Categoría',
        LISTA_DE_OFERTAS: 'Lista de ofertas',
        COBROS_PROVEEDOR: 'Cobros proveedor',
        NUEVO_IRPF: 'Nuevo IRPF',
        LISTA_IRPFS: 'Lista de IRPFs',
        EDITAR_IRPF: 'Editar IRPF',
        TIPO_GRUPO: 'Tipo grupo',
        EDITAR_TIPO_GRUPO: 'Editar tipo grupo',
        NUEVO_TIPO_GRUPO: 'Nuevo tipo grupo',
        TIPOS_GRUPO: 'Tipos grupo',
        NOTICIA: 'Noticia',
        SELECCIONE_UNA_INSCRIPCION: 'Seleccione un inscripción',
        LISTA_DE_INSCRIPCIONES: 'Lista de inscripciones',
        CURRICULUM: 'Currículum',
        NIF: 'NIF',
        MOVIL: 'Móvil',
        APELLIDOS: 'Apellidos',
        AGREGAR_PERSONA: 'Agregar persona',
        TIPO_CONTRATO: 'Tipo contrato',
        NUM_VACANTES: 'Núm. vacantes',
        SALARIO: 'Salario',
        FECHA_PUBLICACION_INICIO: 'Fecha publicación inicio',
        FECHA_PUBLICACION_FIN: 'Fecha publicación fin',
        IDIOMA: 'Idioma',
        AGREGAR_NIVEL_IDIOMA: 'Agregar nivel idioma',
        IDIOMAS: 'Idiomas',
        AGREGAR_ESTUDIO: 'Agregar estudio',
        INTERES: 'Interés',
        EXPERIENCIA: 'Experiencia',
        FUNCION: 'Función',
        LISTA_DE_PAGOS: 'Lista de pagos',
        PAGO: 'Pago',
        PAGOS: 'Pagos',
        LISTA_FACTURAS_COMPRA: 'Lista de facturas compra',
        NUEVO_PAGO: 'Nuevo pago',
        EDITAR_PAGO: 'Editar pago',
        NUEVA_FACTURA_COMPRA: 'Nueva factura compra',
        PRECIO_UNITARIO: 'Precio unitario',
        ESTUDIOS: 'Estudios',
        OFERTAS_EMPLEOS: 'Ofertas empleos',
        NOMBRE_OFERTA: 'Nombre de la oferta',
        INTERES_OFERTA: 'Interés en la oferta',
        NOTIFICAR_EMPRESA: 'Notificar a la empresa',
        MARCAR_DOCUMENTO_PREFERIDO: 'Marcar documento preferido',
        CV_DEFECTO: 'CV por defecto',
        HABILITAR_OFERTAS: 'Habilitar ofertas',
        RECIBIR_NOTIFICACIONES_EMAIL: 'Recibir notificaciones Email',
        ELIMINAR_INSCRIPCION: 'Eliminar inscripción',
        TABLON_OFERTAS: 'Tablón ofertas',
        MIS_OFERTAS: 'Mis ofertas',
        VISIBLE_POR_EMPRESA: 'Visible por empresa',
        CURRICULUMS: 'Curriculums',
        AGREGAR_CURRICULUM: 'Agregar curriculum',
        PREFERIDO: 'Preferido',
        EDITAR_CURRICULUM: 'Editar curriculum',
        INTERESADO: 'Interesado',
        FICHA_PERSONA: 'Ficha persona',
        NO_EXISTE_CV_ASOCIADO_A_OFERTA: 'No existe un CV asociado a esta oferta',
        VER_MAS_INFORMACION_PERSONA: 'Ver más información de la persona',
        BOLSA_DE_TRABAJO_EMPRESA: 'Bolsa de trabajo empresa',
        BUSCADOR: 'Buscador',
        NOTIFICADO: 'Notificado',
        BUSCADOR_PERSONAS: 'Buscador de personas',
        LIMPIAR_TODO: 'Limpiar todo',
        INSCRIBIR_PERSONAS: 'Inscribir personas',
        PERFIL_CANDIDATO: 'Perfil candidato',
        SE_HAN_ENCONTRADO: 'Se han encontrado',
        RESULTADOS: 'Resultados',
        VISIBLE_EMPRESA: 'Visible empresa',
        REQUERIR_TODOS_IDIOMAS: 'Requerir todos los idiomas',
        REQUERIR_TODOS_ESTUDIOS: 'Requerir todos los estudios',
        REQUERIR_TODAS_ESPECIALIDADES: 'Requerir todas las especialidades',
        REQUERIR_TODAS_EXPERIENCIAS: 'Requerir todas las experiencias',
        REQUERIR_TODOS_INTERESES: 'Requerir todos los intereses',
        IR_A_FICHA_PERSONA: 'Ir a la ficha de la persona',
        NOTIFICAR_ENCARGADOS: 'Notificar encargados',
        CREAR_PLANTILLA: 'Crear Plantilla',
        SI_CAMBIAR_ESTADO: 'Si, cambiar estado',
        DATOS_ALQUILER: 'Datos alquiler',
        FECHA_RECOGIDA_DESDE: 'Fecha recogida desde',
        FECHA_RECOGIDA_HASTA: 'Fecha recogida hasta',
        FECHA_DEVOLUCION_DESDE: 'Fecha devolución desde',
        FECHA_DEVOLUCION_HASTA: 'Fecha devolución hasta',
        NOMBRE_ADJUNTO: 'Nombre adjunto',
        PUEDE_SELECCIONAR_MULTIPLES_FICHEROS: 'Puede seleccionar múltiples ficheros',
        IMPORTE_TOTAL: 'Importe total',
        VER_ENTIDAD: 'Ver entidad',
        VER_PERSONA: 'Ver persona',
        PARRAFO_MOSTRAR_FACTURAS_PENDIENTES: 'Sólo se muestran facturas pendientes',
        AGREGAR_UNA_IMAGEN: 'Agregar una imagen',
        LISTA_DE_PERSONAS_ENTIDADES: 'Lista de personas - entidades',
        LISTA_DE_ENTIDADES_PERSONAS: 'Lista de entidades - personas',
        N_VISADOS: 'N. Visados',
        PENDIENTE: 'Pendiente',
        COBRADO: 'Cobrado',
        IMPAGADO: 'Impagado',
        PAGADO: 'Pagado',
        PRECOLEGIADO: 'Precolegiado',
        COLEGIADO_EXTERNO: 'Colegiado externo',
        NO_ASOCIADO: 'No asociado',
        NO_COLEGIADO: 'No colegiado',
        TODOS: 'Todos',
        VIGENTE: 'Vigente',
        PENDIENTES: 'Pendientes',
        COBRADAS: 'Cobradas',
        OTROS: 'Otros',
        PROFORMAS: 'Proformas',
        ABONO: 'Abono',
        RESPONSABILIDAD_CIVIL: 'Responsabilidad civil',
        CASADOA: 'Casado/a',
        SOLTEROA: 'Soltero/a',
        DIVORCIADOA: 'Divorciado/a',
        VIUDOA: 'Viudo/a',
        CONVIVIENTE: 'Conviviente',
        SEPARADOA: 'Separado/a',
        ANULADO: 'Anulado',
        BORRADOR: 'Borrador',
        EN_INCIDENCIA: 'En incidencia',
        DOCUMENTO_A_SELLAR: 'Documento a sellar',
        DOCUMENTO_DE_ADMINISTRACION: 'Documento de administración',
        PASAPORTE: 'Pasaporte',
        CIF_EXTRANJERO: 'CIF Extranjero',
        SOPORTE_DIGITAL: 'Soporte digital',
        SOPORTE_PAPEL: 'Soporte papel',
        TRAMITE: 'Trámite',
        FINALIZADO: 'Finalizado',
        EXTERNO: 'Externo',
        GENERAL: 'General',
        LISTA_DE_ESPERA: `Lista de espera`,
        PENDIENTE_DE_PAGO: 'Pendiente de pago',
        ANULADA: 'Anulada',
        INSCRIPCION: 'Inscripción',
        TRANSFERENCIA: 'Transferencia',
        REMESA: 'Remesa',
        CAROUSEL: 'Carousel',
        MIXTO: 'Mixto',
        VENTA: 'Venta',
        COMPRA: 'Compra',
        ENTIDAD_CLIENTE: 'Entidad cliente',
        ENTIDAD_PROVEEDOR: 'Entidad proveedor',
        PERSONA_CLIENTE: 'Persona cliente',
        PERSONA_PROVEEDOR: 'Persona proveedor',
        EXPORTADOS: 'Exportados',
        NO_EXPORTADOS: 'No exportados',
        PENDIENTE_DE_RECOJO: 'Pendiente de recojo',
        PENDIENTE_DE_ENTREGA: 'Pendiente de entrega',
        ACTIVOS: 'Activos',
        EMPRESA: 'Empresa',
        IMPORTE_PRESUPUESTO: 'Importe Presupuesto',
        NUM_FACTURA: 'Nº Factura',
        ABONA: 'Abona',
        BANCO: 'Banco',
        COD_BANCO: 'Cod. Banc',
        TOTAL: 'Total',
        ELEMENTOS: 'Elementos',
        INICIAR_SESION: 'Iniciar sesión',
        OLVIDO_SU_CLAVE: '¿Olvidó su clave?',
        ACCEDER: 'Acceder',
        USUARIOA: 'Usuario/a',
        PARRAFO_RECUPERAR_CLAVE: `Si está registrado y ha olvidado su nombre de usuario/ay/o clave, o ésta ha quedado bloqueada por sucesivos intentos fallidos, introduzca los datos requeridos.`,
        DESTINATARIO_NOMBRE: 'Destinatario nombre',
        REENVIANDO_PENDIENTES: 'Reenviant pendents',
        LISTA_DE_ASOCIADOS: 'Lista de asociados',
        TRAMITADOR: 'Tramitador',
        IMPORTE_CUOTA: 'Importe Cuota',
        PROFORMA: 'Proforma',
        PARRAFO_FECHA_REGISTRO_CUOTA: `La fecha de registro debe estar en el rango de generación de la cuota`,
        CONCEPTO: 'Concepto',
        CANTIDAD_TOTAL: 'Cantidad total',
        CANTIDAD_ENVIADAS: 'Cantidad enviadas',
        IMPORTE_BASE: 'Importe BASE',
        IMPORTE_IVA: 'Importe IVA',
        IMPORTE_IRPF: 'Importe IRPF',
        TITULAR: 'Titular',
        CUENTA_CORRIENTE: 'Cuenta corriente',
        TELEFONO: 'Teléfono',
        CLASE_SEGURO: 'Clase Seguro',
        FECHA_CADUCIDAD: 'Fecha caducidad',
        ASEGURADORA: 'Aseguradora',
        MODALIDAD_SEGURO: 'Modalidad seguro',
        ABONANTE: 'Abonante',
        TIPO_SERVICIO: 'Tipo servicio',
        SERIE: 'Serie',
        TIPO_NOTIFICACION: 'Tipo notificación',
        USUARIO: 'Usuario',
        PERFILES: 'Perfiles',
        NUMERO_DE_PEDIDO: 'Número de pedido',
        COBRO: 'Cobro',
        FECHA_REGISTRO_DESDE: 'Fecha registro desde',
        FECHA_REGISTRO_HASTA: 'Fecha registro hasta',
        REFERENCIA: 'Referencia',
        COLEGIO: 'Colegio',
        MOTIVO_DE_BAJA: 'Motivo de baja',
        IMPORTE_POR_DEFECTO: 'Importe por defecto',
        GESTIONAR_REMESAS: 'Gestionar remesas',
        CREAR_REMESA: 'Crear remesa',
        PARRAFO_FILTRAR_PRIMERO_CUENTA_PROPIA: 'Debe filtrar primero por cuenta propia',
        CREAR_FICHERO_SEPA_XML_COR1: 'Crear fichero SEPA XML COR1',
        FICHERO_SEPA_XML_COR1: 'fichero SEPA XML COR1',
        CREAR_FICHERO_SEPA_XML_CORE: 'Crear fichero SEPA XML CORE',
        FICHERO_SEPA_XML_CORE: 'fichero SEPA XML CORE',
        NUM_VISADO: 'Núm. visado',
        NOMBRE_COLEGIADO: 'Nombre colegiado',
        SUFIJO_REMESA: 'Sufijo remesa',
        DESCRIPCION_REMESA: 'Descripción remesa',
        FECHA_SOPORTE: 'Fecha soporte',
        IMPORTE_DESCUENTO: 'Importe descuento',
        FECHA_ALTA_DESDE: 'Fecha alta desde',
        FECHA_ALTA_HASTA: 'Fecha alta hasta',
        FECHA_BAJA_DESDE: 'Fecha baja desde',
        FECHA_BAJA_HASTA: 'Fecha baja hasta',
        NUEVA_IMAGEN: 'Nueva imagen',
        CONTENIDO_COMUNICACION: 'Contenido comunicación',
        ENVIADO_DESDE: 'Enviado desde',
        ENVIADO_HASTA: 'Enviado hasta',
        FECHA_FIRMA: 'Fecha firma',
        FECHA_CONSTITUCION: 'Fecha constitución',
        PARRAFO_SELECCION_SEDE_DATOS: 'Seleccione una sede para obtener sus datos',
        NOMBRE_POLIZA: 'Nombre póliza',
        CLASE: 'Clase',
        NOMBRE_MUTUALISTA: 'Nombre mutualista',
        TIPO_RELACION: 'Tipo relación',
        COD_RELACION: 'Cod. relación',
        ESTADO_PERSONA: 'Estado persona',
        TIPO_PERSONA: 'Tipo persona',
        MOTIVO_EXENTO_CUOTA: 'Motivo exento de cuota',
        APELLIDO: 'Apellido',
        USUARIO_WEB: 'Usuario web',
        FECHA_RESGUARDO: 'Fecha resguardo',
        FECHA_EXPEDICION: 'Fecha expedición',
        FECHA_HOMOLOGACION: 'Fecha homologación',
        CODIGO_DEL_CENTRO: 'Código del centro',
        NUM_REGISTRO_CENTRO: 'Núm. Registro centro',
        NUM_REGISTRO_MINISTERIO: 'Núm. Registro ministerio',
        DESCRIPCION_DE_LA_PLANTILLA: 'Descripción de la plantilla',
        CODIGO_TIPO_NOTIFICACION: 'Código tipo notificación',
        GESTOR_DE_PERFILES: 'Gestor de perfiles',
        GESTOR_DE_MODULOS: 'Gestor de módulos',
        NUM_EXPEDIENTE: 'Núm. expediente',
        IDIOMA_VISADO: 'Idioma visado',
        TIPO_CONDICION_DE_PAGO: 'Tipo condición de pago',
        POR_DEFECTO: 'Por defecto',
        GENERAR_FACTURAS_AGRUPADAS: 'Generar facturas agrupadas',
        CAMBIAR_CONDICION_DE_PAGO: 'Cambiar condición de pago',
        ELIMINAR_LINEA_DE_FACTURA: 'Eliminar línea de factura',
        NUM_PROFORMA: 'Nº Proforma',
        PRESUPUESTO: 'Presupuesto',
        BASE_IMPONIBLE: 'Base imponible',
        CONFIRMAR_CREAR_REMESA: 'Confirmar crear remesa',
        CONDICION_DE_COBRO: 'Condición de cobro',
        ERRORES: 'Errores',
        OBSERVACIONES_DEL_CLIENTE: 'Observaciones del cliente',
        OBSERVACIONES_USO_INTERNO: 'Observaciones (uso interno)',
        GENERAR_FACTURA: 'Generar factura',
        NOTIFICAR_POR_EMAIL: 'Notificar por email',
        NOMBRE_DEL_FICHERO: 'Nombre del fichero',
        EXPORTADOS_A_CONTA: 'Exportados a conta.',
        FECHA_CUOTA_DESDE: 'Fecha cuota desde',
        HASTA_EL: 'Hasta el',
        CUOTA_A_GENERAR: 'Cuota a generar',
        MOTIVO_RECTIFICACION: 'Motivo rectificación',
        TIPO_PROCESOS_CONVENIO: 'Tipo procesos convenio',
        CODIGO_BANCO: 'Código banco',
        TIPO_PASARELA: 'Tipo pasarela',
        ENTIDAD_EMISORA: 'Entidad emisora',
        SERIE_RECTIFICATIVA: 'Serie rectificativa',
        PLANTILLA_FACTURA: 'Plantilla factura',
        ENTIDAD_PREDETERMINADA: 'Entidad predeterminada',
        PORCENTAJE: 'Porcentaje',
        GENERAR: 'Generar',
        CODIGO_APLICACION: 'Código aplicación',
        COD_TIPO_CUOTA: 'Cód. Tipo cuota',
        FECHA_INICIO_PATROCINIO: 'Fecha inicio patrocinio',
        FECHA_FIN_PATROCINIO: 'Fecha fin patrocinio',
        SERVICIO_DE_LINEA: 'Servicio de línea',
        PRECIO_ORIGINAL: 'Precio original',
        PORCENTAJE_DESCUENTO: 'Porcentaje descuento',
        TRAMITACION_RELACIONADA: 'Tramitación relacionada',
        PARRAFO_SELECCIONE_APARATO_MEDIDA: 'Seleccione el aparato de medida',
        DIAS_MINIMOS: 'Días mínimos',
        DIAS_MAXIMOS: 'Días máximos',
        PARRAFO_ESPECIFICA_CONVENIO_APLICAR: 'Especifica el convenio a aplicar',
        PUBLICADO_EN: 'Publicado en',
        NOMBRE_DE_LA_EMPRESA: 'Nombre de la empresa',
        IMAGEN_PRINCIPAL: 'Imagen principal',
        SALON_PRINCIPAL: 'Salón principal',
        SALA: 'Sala',
        PARRAFO_SELECCIONE_ALMENOS_UN_BLOQUE_BUSQUEDA: 'Seleccione al menos un bloque de búsqueda',
        BUSCAR_PERSONAS: 'Buscar personas',
        EMAILS_NO_ENVIADOS: 'E-mails no enviados',
        MODIFICAR_BUSQUEDA: 'Modificar búsqueda',
        RESUMEN_DE_BUSQUEDA: 'Resumen de búsqueda',
        ES_VISADOR: 'Es visador',
        EXPERIENCIA_EN: 'Experiencia en',
        YA_ESTA_INSCRITO_OFERTA: 'Ya está inscrito en la oferta',
        VER_OFERTA_PUBLICADA: 'Ver la oferta publicada',
        IR_A_INSCRIBIRME_OFERTA: `Ir a inscribirme en la oferta`,
        FICHA_DE_LA_OFERTA: `Ficha de la oferta`,
        EXCLUIR_ERRORES: 'Excluir errores',
        RUTA_EMPRESA: 'Ruta empresa',
        PREDETERMINADA: 'Predeterminada',
        VALIDACION_PERSONA_OBLIGATORIA: 'La persona es obligatoria',
        VALIDACION_ENTIDAD_OBLIGATORIA: 'La entidad es obligatoria',
        CUALQUIER_ACREDEDOR_SERVICIO: 'Cualquier acreedor y servicio',
        CARGAR_ESTA_EXPLICA: 'Cargar en esta explica',
        MANDATO: 'Mandato',
        PREDEFINIDA: 'Predefinida',
        EXENTO: 'Exento',
        SIN_ENLACE: 'Sin Enlace',
        ENLACE_INTERNO: 'Enlace interno',
        ENLACE_EXTERNO: 'Enlace externo',
        SUBIR_NUEVO_CV: 'Subir nuevo CV',
        SELECCIONAR_CV_EXISTENTE: 'Seleccionar CV existente',
        ACTUAL: 'Actual',
        EJERCICIO_ANTERIOR: 'Ejercicio anterior',
        PARRAFO_SEGURO_SACAR_HISTORICO: '¿Seguro que desea sacar de histórico?',
        NOTIFICAR_CAMBIO: 'Notificar cambio',
        PARRAFO_SEGURO_PASAR_HISTORICO: '¿Seguro que desea pasar a histórico?',
        COLEGIO_PROFESIONAL: 'Colegio profesional',
        PARRAFO_CLIC_APAREZCAN_NOTICIAS: 'Haga clic donde desea que aparezcan las noticias',
        CONTACTO_PRINCIPAL: 'Contacto principal',
        FOTOGRAFIA: 'Fotografía',
        TIPO_CUOTA: 'Tipo cuota',
        RELACION_PROFESIONAL: 'Relación profesional',
        INDICAR_MODULOS_ASOCIADOS: 'Indicar Módulos asociados',
        EDITAR_PERFILES: 'Editar perfiles',
        INDICAR_PERFIL: 'Indicar perfil',
        INDICAR_PERFIL_ASOCIADO: 'Indicar perfil asociado',
        MENCIONAR_NOMBRE_EMPRESA: 'Mencionar el Nombre de la Empresa',
        ES_CUOTA: 'Es cuota',
        PRIVADO: 'Privado',
        PUBLICAR_WEB: 'Publicar web',
        RUTA: 'Ruta',
        CARGAR_DOCUMENTO: 'Cargar documento',
        BUSCAR_TIPO_TRABAJO: 'Buscar Tipo Trabajo',
        AUTOCARGAR_DATOS_CLIENTE: 'Autocargar datos cliente',
        FIRMA_DE_DOCUMENTOS: 'Firma de documentos',
        PARRAFO_DESCARGAR_INSTALAR_PLUGIN: `Para poder continuar con el proceso de firmas, por favor descargue e instale el plugin`,
        PLUGIN_DE_FIRMA: 'Plugin de Firma',
        NO_NECESARIO_DESCARGAR_DOCUMENTOS: 'No es necesario descargar los documentos para firmarlos',
        PUEDES_FIRMAR_MULTIPLES_DOCUEMNTOS: 'Puedes firmar múltiples documentos a la vez',
        PUEDES_ANADIR_MARCAS: 'Podrás añadir marcas de agua de forma personalizada',
        AGILIZA_PROCESO_FIRMA: 'Agiliza el proceso de firma',
        DESCARGAR_INSTALAR_PLUGIN: 'Descargar e instalar Plugin',
        COMO_INSTALAR_PLUGIN: '¿Cómo instalar el plugin?',
        BAJAR_GUIA_INSTALACION: `Si tiene problemas sobre el conector, baje la guía de instalación`,
        GUIA_DE_INSTALACION: `Guía de instalación`,
        PARRAFO_DESCARGAR_NAVEGADORES: 'Su navegador no es compatible con el Plugin, por favor utilice uno de los siguientes navegadores para continuar con el proceso de firma',
        VER_OTRAS_GUIAS: 'Ver otras guías',
        CONFIGURACION_DEL_SELLO: 'Configuración del sello',
        SELECCIONE_OPCION_EDICION: `Seleccione una opción de edición`,
        COLOCAR_SELLO_UNA_PAGINA: 'Colocar sello en una página',
        PARRAFO_PAGINA_ARRASTRE_SELLO: 'Una vez que esté en la página seleccionada arrastre la imagen del sello hasta donde se quiere colocar',
        PAGINA_SELECCIONADA: 'Página seleccionada',
        PAG: 'Pág.',
        COLOCAR_SELLO_CADA_PAGINA: 'Colocar sello en cada página',
        PARRAFO_MUEVASE_INDICAR_SELLO: 'Muévase por las diferentes páginas del documento para indicar la posición del sello',
        ESTABLECER_PAGINA_REFERENCIA: 'Establecer Página de Referencia',
        PARRAFO_PAGINA_NO_INDIQUE_SELLO: `Esta página se utilizará para aquellas páginas donde no se indique la posición del sello`,
        PARRAFO_INDIQUE_PAGINA_POSICION_SELLO: 'Indique la página de referencia y posición del sello',
        PAGINAS_EDITADAS: 'Páginas editadas',
        PAGINAS_PENDIENTES_DE_EDITAR: `Páginas pendientes de editar`,
        PAGINA_REFERENCIA: 'Página de referencia',
        ORIENTACION_DEL_SELLO: 'Orientación del sello',
        GIRAR: 'Girar',
        PARRAFO_MANTENDRA_GIRO: 'Se mantendrá el giro del sello en todas las páginas del documento',
        PARRAFO_UBIQUESE_FIRMAR_ESCRIBIR_NAVEGACION: 'Ubíquese sobre la página donde desee firmar, puede escribir directamente el número de página o desplácese a través de la navegación',
        PARRAFO_ARRASTRE_IMAGEN_FIRMAR_DOCUMENTO: 'Arrastre la imagen de la firma y suéltela donde desee firmar el documento',
        PARRAFO_CLICK_BOTON_FIRMAR: 'Haga clic sobre el botón de "Firmar" para firmar y finalizar el proceso de firmado',
        PARRAFO_ACTUALIZAR_TODAS_PAGINAS: '¿Quiere actualizar todas las páginas, incluyendo las páginas editadas?',
        MANTENER_PAGINAS_EDITADAS: 'Mantener páginas editadas',
        SI_ACTUALIZAR_TODAS_PAGINAS: 'Sí, actualizar todas las páginas',
        MARCA_VISADO: 'Marca visado',
        COLEGIADOS_SEGUROS_INACTIVOS: 'Colegiados seguros inactivos',
        COBROS_AFECTADOS: 'Cobro(s) afectados',
        NO_HAY_LINEAS_FACTURA: 'No hay líneas de factura',
        CREAR_COBRO: 'Crear Cobro',
        LISTA_SERVICIOS_LINEA: 'Lista de servicios en línea',
        PARRAFO_SEGURO_ANULAR_INSCRIPCION: '¿Está seguro de querer anular la inscripción?',
        PARRAFO_SEGURO_ELIMINAR_EVENTO: `¿Está seguro de que desea eliminar el evento?`,
        SI_ELIMINAR: 'Si, eliminar',
        ANADIR_COLEGIO: 'Añadir colegio',
        EDITAR_COLEGIO: 'Editar colegio',
        EDITAR_VARIABLES: 'Editar variables',
        CODIGO_ESPECIAL: 'Código especial',
        PARRAFO_SEGURO_CAMBIAR_ESTADO_EVENTO_A: 'Está seguro de cambiar el estado del evento en',
        PARRAFO_SEGURO_ELIMINAR_COBRO: '¿Está seguro de querer eliminar el cobro?',
        PARRAFO_SEGURO_ELIMINAR_PROFORMA: '¿Está seguro de que desea eliminar la proforma?',
        PARRAFO_SEGURO_SUPRIMIR_LINEAS_FACTURA: '¿Está seguro de que desea eliminar las líneas de factura seleccionadas?',
        PARRAFO_SEGURO_ELIMINAR_DERECHO: '¿Está seguro de querer eliminar el derecho?',
        PARRAFO_SEGURO_ELIMINAR_ELEMENTO: `¿Está seguro de que desea eliminar el elemento?`,
        PARRAFO_SEGURO_CAMBIAR_ESTADO_A: `Está seguro de que desea cambiar el estado a`,
        FACTURA_RECTIFICADA: 'Factura rectificada',
        FACTURA_RECTIFICATIVA: 'Factura rectificativa',
        NUEVO_BANCO: 'Nuevo Banco',
        EDITAR_BANCO: 'Editar Banco',
        NUEVA_CONDICION_DE_PAGO: 'Nueva Condición pago',
        EDITAR_CONDICION_PAGO: 'Editar Condición de pago',
        NUEVO_SERVICIO: 'Nuevo Servicio',
        EDITAR_SERVICIO: 'Editar Servicio',
        NO_HAY_ARCHIVOS_ADJUNTOS: 'No hay archivos adjuntos',
        NO_HAY_PATROCINADORES: 'No hay patrocinadores',
        PARRAFO_SELECCIONE_ESTADO_CAMBIAR: 'Seleccione un estado a cambiar',
        PARRAFO_SELECCIONE_CONDICION_PAGO_CAMBIAR: 'Seleccione la condición de pago a cambiar',
        SI_CAMBIAR: 'Si, cambiar',
        LISTA_LINEAS_FACTURA: 'Lista de Líneas de Facturas',
        LISTA_REMESAS: 'Lista de Remesas',
        EXCEL_VISADOS: 'Excel visados',
        EXCEL_VISADOS_TRABAJOS: 'Excel visados trabajos',
        NUM_POLIZA: 'Núm. póliza',
        DAR_DE_ALTA: 'Dar de alta',
        CREAR_TICKET: `Crear ticket`,
        URGENCIA: `Urgencia`,
        PRODUCTO: `Producto`,
        PERSONA_REGISTRO: `Persona registro`,
        TECNICO_ASOCIADO: `Técnico asociado`,
        FECHA_INICIO_INTERVENCION: `Fecha inicio intervención`,
        FECHA_FIN_INTERVENCION_DESDE: `Fecha fin intervención desde`,
        FECHA_FIN_INTERVENCION_HASTA: `Fecha fin intervención hasta`,
        AREA_TICKETING: `Área Ticketing`,
        TICKETS: `Tickets`,
        FECHA_MODIFICACION: `Fecha modificación`,
        TIEMPO: `Tiempo`,
        NUEVO_TICKET: `Nuevo ticket`,
        ASIGNAR_TECNICO: `Asignar técnico`,
        MUY_URGENTE: `Muy urgente`,
        OBSERVACIONES_TECNICO: `Observaciones técnico`,
        ENVIAR_MENSAJE: `Enviar mensaje`,
        PRODUCTOS: `Productos`,
        EDITAR_PRODUCTO: `Editar producto`,
        NUEVO_PRODUCTO: `Nuevo producto`,
        AGREGAR_ENTIDAD: `Agregar entidad`,
        ESTADOS: `Estados`,
        NUEVO_ESTADO: `Nuevo estado`,
        EDITAR_ESTADO: `Editar estado`,
        ESTADOS_TICKET: `Estados Ticket`,
        NUEVA_URGENCIA: `Nueva urgencia`,
        EDITAR_URGENCIA: `Editar urgencia`,
        URGENCIAS_TICKET: `Urgencias Ticket`,
        URGENCIAS: `Urgencias`,
        TICKET: `Ticket`,
        EDITAR_TICKET: `Editar ticket`,
        ENVIAR_SIN_COMUNICAR: `Enviar sin comunicar`,
        GESTOR_TIPO_DE_MODULOS: `Gestor Tipo de módulos`,
        LISTA_TIPOS_DE_MODULOS: `Lista tipos de módulos`,
        TIPO_DE_MODULO: `Tipo de módulo`,
        EDITAR_TIPO_DE_MODULO: `Editar Tipo de módulo`,
        NUEVO_TIPO_DE_MODULO: `Nuevo Tipo de módulo`,
        CERRAR_TICKET: `Cerrar Ticket`,
        REABRIR_TICKET: `Reabrir Ticket`,
        TECNICO: `Técnico`,
        ENVIAR_CERRAR_TICKET: `Enviar y cerrar ticket`,
        ALERT_OPCION_DISPONIBLE_CREAR_ENTIDAD: 'Las personas y productos se gestionarán luego de crear la entidad',
        AGREGAR_PRODUCTO: 'Agregar producto',
        PLANT_ALIAS_CLIENTES: `Plant. alias clientes`,
        PLANT_ALIAS_TECNICOS: `Plant. alias técnicos`,
        FECHA_ALTA: `Fecha de alta`,
        BUZON_EMAILS: `Buzón emails`,
        FECHA_RECIBIDO: `Fecha recibido`,
        REMITENTE: `Remitente`,
        DESTINATARIO: `Destinatario`,
        SOLO_PENDIENTES: `Solo pendientes`,
        SINCRONIZANDO_BUZON_EMAILS: `Sincronizando Buzon Emails`,
        SINCRONIZANDO: `Sincronizando`,
        DESTINATARIOS: `Destinatarios`,
        ELIMINAR_TICKET: 'Eliminar Ticket',
        PARRAFO_SEGURO_ELIMINAR_TICKET: '¿Está seguro de querer eliminar el ticket?',
        SINCRONIZAR: 'Sincronizar',
        RELACIONES_PERSONA_ENTIDAD: `Relaciones Persona-Entidad`,
        ADMINISTRADOR: `Administrador`,
        VER_MENOS: `Ver menos`,
        ENVIADO_POR: `Enviado por`,
        TICKETS_ACTIVOS: `Tickets activos`,
        SOLO_ADMIN: `Solo Admin`,
        OCULTO: `Oculto`,
        EDITAR_MENSAJE: `Editar mensaje`,
        GENERAR_CERTIFICADO: `Generar certificado`,
        NO_PUBLICAR: `No publicar`,
        NO_COMUNICAR: `No comunicar`,
        GENERANDO_FACTURA: `Generando factura`,
        P_GUARDAR_NO_GENERAR: `El guardado de los datos no afectará a la factura generada`,
        P_MENSAJE_PUBLICO: `Este campo será visible a los usuarios en la parte pública`,

    }
}

export default lang;